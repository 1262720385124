import {Box, Stack, useTheme} from "@mui/material";
import {ReactNode} from "react";

interface Props {
  logo: any;
  children: ReactNode;
}
export default function SizeaFormLine(props: Props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 1,
      }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          borderColor: theme.palette.primary.main,
          borderLeftWidth: 5,
          borderLeftStyle: "solid",
        }}>
        <Box
          sx={{
            maxWidth: 62,
            padding: 1,
            paddingTop: 3,
            paddingLeft: 2,
          }}>
          {props.logo}
        </Box>
        <Box
          sx={{
            width: "100%",
          }}>
          {props.children}
        </Box>
      </Stack>
    </Box>
  );
}
