import {Roughness} from "../../app/pre-sale/implantation-v2/model/Roughness";

import roughness_0 from "../../assets/roughness/0.png";
import roughness_2_aeroport from "../../assets/roughness/2_aeroport.png";
import roughness_2_rase_campagne from "../../assets/roughness/2_rase_campagne.png";
import roughness_3a_bocage from "../../assets/roughness/3a_bocage.png";
import roughness_3b_bocage_dense from "../../assets/roughness/3b_bocage_dense.png";
import roughness_3b_zone_industrielle from "../../assets/roughness/3b_zone_industrielle.png";
import roughness_4_foret from "../../assets/roughness/4_foret.png";
import roughness_4_ville from "../../assets/roughness/4_ville.png";

const roughnessData = {
  France: [
    {
      label: "Mer ou lac",
      description:
        "Mer ou zone côtière exposée aux vents de mer, lac et plan d'eau parcouru par le vent sur une distance d'au moins 5 km",
      z0: 0.005,
      zmin: 1,
      color: "rgb(255, 0, 0)",
      images: [roughness_0],
    },
    {
      label: "Aucun obstacle",
      description:
        "Rase campagne, avec ou non quelques obstacles isolés (arbres, bâtiments, etc.) séparés les uns des autres de plus de 40 fois leur hauteur",
      z0: 0.05,
      zmin: 2,
      color: "rgb(255, 112, 77)",
      images: [roughness_2_aeroport, roughness_2_rase_campagne],
    },
    {
      label: "Bocage",
      description: "Campagne avec des haies, vignoble, bocage, habitat dispersé",
      z0: 0.2,
      zmin: 5,
      color: "rgb(140, 217, 140)",
      images: [roughness_3a_bocage],
    },
    {
      label: "Zone industrielle",
      description: "Zone urbanisée ou industrielle, bocage dense, verger",
      z0: 0.5,
      zmin: 9,
      color: "rgb(0, 153, 51)",
      images: [roughness_3b_bocage_dense, roughness_3b_zone_industrielle],
    },
    {
      label: "Zone urbanisée / forêt",
      description:
        "Zone urbaine dont au moins 15 % de la surface est recouverte de bâtiments dont la hauteur moyenne est supérieure à 15 m, forêt",
      z0: 1,
      zmin: 15,
      color: "rgb(0, 77, 26)",
      images: [roughness_4_foret, roughness_4_ville],
    },
  ],
};

export const apiRoughness = {
  getRoughnessTypes: async function (): Promise<Array<Roughness>> {
    return Promise.all(roughnessData["France"] as Array<Roughness>);
  },
};
