import {Information} from "../interfaces/trace/Trace";
import {formatConsumptionLevel} from "./FormatConsumptionLevel";

const defaultFormat = (information: Information) => {
  return JSON.stringify(information, null, "\t");
};

const translateEnableDisable = (information: Information) => {
  if (information.value === false) {
    return "Désactivé";
  }
  if (information.value === true) {
    return "Activé";
  }
  return defaultFormat(information);
};

const translateUpdateEnergyCalculationMethod = (information: Information) => {
  if (information.value === true) {
    return "Énergie (W/h)";
  }
  if (information.value === false) {
    return "Puissance (W)";
  }
  return defaultFormat(information);
};

const translateUpdateRestrictedValue = (information: Information) => {
  return `${information.value} W`;
};

const translateUpdateConsumptionLevel = (information: Information) => {
  const formattedConsumptionLevel = formatConsumptionLevel(information.value);
  if (formattedConsumptionLevel === "N/A") {
    return defaultFormat(information);
  }
  return formattedConsumptionLevel;
};

const translateToHuman = (information: Information, type?: string) => {
  switch (type) {
    case "UPDATE_RESTRICTED_POWER":
    case "UPDATE_CAN_GENERATE_API_KEY":
      return translateEnableDisable(information);
    case "UPDATE_ENERGY_CALCULATION_METHOD":
      return translateUpdateEnergyCalculationMethod(information);
    case "UPDATE_RESTRICTED_VALUE":
      return translateUpdateRestrictedValue(information);
    case "UPDATE_CONSUMPTION_LEVEL":
      return translateUpdateConsumptionLevel(information);
    default:
      return defaultFormat(information);
  }
};

export default translateToHuman;
