type dataWithPlantId = {
  plantId: number;
};

type objectMappedByPlant = {
  [key: string]: dataWithPlantId[];
};

function concatenateObjectOfArray(data: objectMappedByPlant): dataWithPlantId[] {
  // concatenate all the arrays of an objects
  const arrayOfArrays = Object.values(data);
  const concatenatedArray = arrayOfArrays.flat(1);
  return concatenatedArray;
}

function filterObjectsByPlants(data: objectMappedByPlant, plants: number[]): objectMappedByPlant {
  // only keep the values of data which key is in the plants array
  const res: objectMappedByPlant = {};
  plants.forEach((plantId) => {
    if (plantId in data) {
      res[plantId] = data[plantId];
    }
  });
  return res;
}

function mapArrayByPlants(data: dataWithPlantId[]): objectMappedByPlant {
  // create an object where each key is a plantId and each value is an array of object
  const res: objectMappedByPlant = {};
  for (const element of data) {
    // plantId is already present in res so we push the object to corresponding key
    if (Object.keys(res).includes(element.plantId.toString())) {
      res[element.plantId.toString()].push(element);
    } else {
      // plantId is not in res so we create a new entry
      res[element.plantId.toString()] = [element];
    }
  }
  return res;
}

export default function filterArrayByPlants(data: dataWithPlantId[], plants: number[]): dataWithPlantId[] {
  const objectMapByPlants = mapArrayByPlants(data);
  const objectFilteredPlants = filterObjectsByPlants(objectMapByPlants, plants);
  const arrayFilteredByPlants: dataWithPlantId[] = concatenateObjectOfArray(objectFilteredPlants);
  return arrayFilteredByPlants;
}
