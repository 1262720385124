import {UploadFile} from "antd";

interface PlantInput {
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  timezone: string;
  consumptionFileUuid: string | null;
  consumptionFileTypeEnum: ConsumptionFileTypeRef | null;
  dateOptionEnum: DateOptionRef | null;
  startDate: string | null;
  endDate: string | null;
  mapImageFileUuid: string | null;
}

interface TrackerInput {
  trackerTypeId: string | null;
  trackerQuantity: number;
  customRatedPower: number;
}

interface OtherProductionSourceInput {
  productionFileUuid: string;
  name: string;
}

interface ProductionInput {
  isRestricted: boolean;
  trackerInputs: TrackerInput[];
  otherProductionSourceInputs: OtherProductionSourceInput[];
}

interface WaterPointInput {
  uuid: string;
  hotWaterRequirementFileUuid: string | null;
  hotWaterTankType: HotWaterTankTypeRef;
  immersionHeaterTypeId: string;
  immersionHeaterQuantity: number;
  targetTemperature: number;
  volumeCuve: number | null;
}

interface EssInput {
  essTypeId: string;
  nbBattery: number;
  nbConverter: number;
  batteryTypeId: string;
  converterTypeId: string;
  rescuedReserve: number;
}

interface Simulation {
  uuid: string;
  template: string | null;
  customTemplate: string | null;
  plantInput: PlantInput;
  consumptionFiles: UploadFile[];
  productionInput: ProductionInput;
  waterPointInputs: WaterPointInput[];
  essInputs: EssInput[];
  isCustom: boolean;
}

export interface ImmersionHeaterType {
  id: string;
  label: string;
}

export enum HotWaterTankTypeRef {
  BALLON = "BALLON",
  CUVE = "CUVE",
}

export enum ConsumptionFileTypeRef {
  SIZEA = "SIZEA",
  ENEDIS = "ENEDIS",
  NO_CONSUMPTION = "NO_CONSUMPTION",
}

export enum DateOptionRef {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export interface Placeholder {
  label: string;
  description: string;
}

export interface Placeholder {
  label: string;
  description: string;
}

export interface Template {
  name: string;
}

export default Simulation;
