import "./App.css";
import keycloak from "./Keycloak";
import {useEffect, useMemo, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useTheme} from "@mui/material/styles";
import {styled} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Switch from "@mui/material/Switch";

import SvgIcon from "@mui/material/SvgIcon";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";

// Icons
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LogoutIcon from "@mui/icons-material/Logout";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import BuildIcon from "@mui/icons-material/Build";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonIcon from "@mui/icons-material/Person";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import AppsIcon from "@mui/icons-material/Apps";

import logo from "./assets/logo/okwind_logo_small.png";

import AppVersion from "./AppVersion";
import {AppBar, Drawer, DrawerHeader} from "./Drawer";
import {useNavigate, useLocation} from "react-router-dom";
import {RootState} from "./store/store";
import {toggleTheme} from "./store/theme/theme.store";
import React from "react";
import {Collapse} from "@mui/material";

interface UserInfo {
  name: string;
  email: string;
}

interface MenuItemDef {
  label: string;
  icon: React.ReactElement;
  url: string;
  subMenuItem: MenuItemDef[];
}

interface MenuItemProps {
  menuItemDef: MenuItemDef;
  isMenuOpened: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  collapsable?: boolean;
}

interface MenuItemGraph {
  itemListSizea: MenuItemDef[];
  itemListResource: MenuItemDef[];
  itemListFleetManagement: MenuItemDef[];
  itemListPlatformAdministration: MenuItemDef[];
}

interface MenuItemBlockProps {
  itemList: MenuItemDef[];
  isMenuOpened: boolean;
  handleDrawerOpen: () => void;
}

interface SecondMenuItemBlockProps {
  menuItem: MenuItemDef;
  isMenuOpened: boolean;
  handleDrawerOpen: () => void;
}

const MaterialUISwitch = styled(Switch)(({theme}) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff",
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff",
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

function SecondMenuItemBlock(props: SecondMenuItemBlockProps) {
  const [openCollapse, setOpenCollapse] = React.useState(true);
  const navigate = useNavigate();

  function handleOpenCollapse() {
    if (!props.isMenuOpened) props.handleDrawerOpen();
    setOpenCollapse(!openCollapse);
  }

  useEffect(() => {
    setOpenCollapse(props.isMenuOpened);
  }, [props.isMenuOpened]);

  return (
    <div>
      {props.menuItem.subMenuItem.length === 0 && (
        <AppMenuItem
          menuItemDef={props.menuItem}
          isMenuOpened={props.isMenuOpened}
          onClick={() => {
            if (props.menuItem.url) navigate(props.menuItem.url);
          }}
        />
      )}

      {props.menuItem.subMenuItem.length > 0 && (
        <>
          <AppMenuItem
            menuItemDef={props.menuItem}
            isMenuOpened={props.isMenuOpened}
            onClick={handleOpenCollapse}
            collapsable={openCollapse}
          />
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <List
              style={{
                marginLeft: "15px",
                borderLeft: "1px solid #35ba0746",
                paddingLeft: "10px",
                marginBottom: "10px",
              }}>
              {props.menuItem.subMenuItem.map((thirdLvlItem: MenuItemDef, index3: number) => (
                <AppMenuItem
                  key={index3}
                  menuItemDef={thirdLvlItem}
                  isMenuOpened={props.isMenuOpened}
                  onClick={() => {
                    if (thirdLvlItem.url) navigate(thirdLvlItem.url);
                  }}
                />
              ))}
            </List>
          </Collapse>
        </>
      )}
    </div>
  );
}

function MenuItemBlock(props: MenuItemBlockProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      {props.itemList.map((firstLvlItem: MenuItemDef, index1: number) => {
        return (
          <div key={index1}>
            {firstLvlItem.subMenuItem.length > 0 && (
              <Typography
                style={{
                  marginLeft: 20,
                  marginTop: -5,
                  marginBottom: 0,
                  color: theme.palette.mode === "dark" ? theme.palette.common.white : "rgb(18, 25, 38)",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  display: props.isMenuOpened ? "block" : "none",
                }}
                variant="caption"
                gutterBottom={true}>
                {firstLvlItem.label}
              </Typography>
            )}

            {firstLvlItem.subMenuItem.length === 0 && (
              <AppMenuItem
                menuItemDef={firstLvlItem}
                isMenuOpened={props.isMenuOpened}
                onClick={() => {
                  if (firstLvlItem.url) navigate(firstLvlItem.url);
                }}
              />
            )}
            <List style={{marginLeft: "15px"}}>
              {firstLvlItem.subMenuItem.map((secondLvlItem: MenuItemDef, index2: number) => (
                <SecondMenuItemBlock
                  key={index2}
                  menuItem={secondLvlItem}
                  isMenuOpened={props.isMenuOpened}
                  handleDrawerOpen={props.handleDrawerOpen}
                />
              ))}
            </List>
          </div>
        );
      })}
    </>
  );
}

function OkwindIcon() {
  const theme = useTheme();
  return (
    <SvgIcon>
      <svg
        style={{
          width: "100%",
          height: "54px",
          fill: theme.palette.mode === "dark" ? "white" : "black",
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 129 36"
        enableBackground="new 0 0 129 36">
        <g>
          <path
            d="M69.29,0.71v20.3c0,0.27,0.22,0.49,0.49,0.49h3.77c0.27,0,0.49-0.22,0.49-0.49V0.71
          c0-0.27-0.22-0.49-0.49-0.49h-3.77C69.51,0.22,69.29,0.44,69.29,0.71z"></path>
          <path
            d="M95.41,4.7c-0.98-2.44-3.2-4.23-5.89-4.61c-0.05-0.01-0.09-0.02-0.15-0.02c-0.3-0.03-0.6-0.06-0.91-0.06
          h-4.07c-0.31,0-0.61,0.02-0.91,0.06c-3.71,0.45-6.59,3.62-6.59,7.46v13.49c0,0.27,0.22,0.49,0.49,0.49h3.99
          c0.27,0,0.49-0.22,0.49-0.49V7.49c0-1.39,1.13-2.52,2.52-2.52h4.11c1.38,0,2.51,1.12,2.51,2.51v13.53c0,0.27,0.22,0.49,0.49,0.49
          h3.99c0.27,0,0.49-0.22,0.49-0.49V7.52C95.97,6.53,95.77,5.57,95.41,4.7z"></path>
          <path
            d="M17.71,0.22H11.3C5.03,0.22,0,4.14,0,10.83C0,17.52,4.88,21.5,11.36,21.5h6.35c2.56,0,4.63-2.07,4.63-4.63
          V4.85C22.34,2.29,20.27,0.22,17.71,0.22z M17.12,16.49c0,0.21-0.17,0.39-0.39,0.39h-5.1c-3.68,0-6.23-2.16-6.23-6.03
          c0-3.87,2.41-6.03,6.23-6.03h5.1c0.22,0,0.39,0.17,0.39,0.39v11.26V16.49z"></path>
          <path
            d="M98.97,4.85v12.03c0,2.56,2.07,4.63,4.63,4.63h6.35c6.48,0,11.36-4.18,11.36-10.67
          c0-6.49-5.04-10.62-11.31-10.62h-6.41c-2.56,0-4.63,2.07-4.63,4.63H98.97z M104.19,5.23c0-0.22,0.17-0.39,0.39-0.39h5.1
          c3.83,0,6.23,2.24,6.23,6.03c0,3.86-2.56,6.03-6.23,6.03h-5.1c-0.22,0-0.39-0.19-0.39-0.39V5.23z"></path>
          <path
            d="M66.28,0.71v13.27c0,4.15-3.37,7.52-7.52,7.52h-1.45c-2.02,0-3.87-0.81-5.23-2.12
          c-1.35,1.31-3.19,2.12-5.23,2.12h-1.46c-4.15,0-7.51-3.36-7.51-7.51V0.71c0-0.27,0.22-0.49,0.49-0.49h3.64
          c0.27,0,0.49,0.22,0.49,0.49v13.27c0,1.6,1.29,2.89,2.89,2.89h1.45c1.59,0,2.88-1.29,2.88-2.88V0.71c0-0.27,0.22-0.49,0.49-0.49
          h3.7c0.27,0,0.49,0.22,0.49,0.49v13.27c0,1.6,1.29,2.89,2.89,2.89h1.45c1.6,0,2.89-1.29,2.89-2.89V0.71c0-0.27,0.22-0.49,0.49-0.49
          h3.64c0.27,0,0.49,0.22,0.49,0.49H66.28z"></path>
          <path
            d="M30.58,0.22c-0.3,0-0.58,0.15-0.75,0.39l-5.69,8.13c-0.88,1.25-0.88,2.91-0.02,4.16l5.64,8.19
          c0.17,0.25,0.46,0.4,0.76,0.4l5.14-0.05c0.26,0,0.42-0.3,0.27-0.52l-6.67-9.48c-0.34-0.47-0.34-1.11,0.01-1.58l6.63-9.12
          c0.16-0.22,0-0.52-0.27-0.52H30.58z"></path>
          <path
            fill="#2FB109"
            d="M125.77,22.08c1.541,0,2.79-1.249,2.79-2.79c0-1.541-1.249-2.79-2.79-2.79s-2.79,1.249-2.79,2.79
          C122.98,20.831,124.229,22.08,125.77,22.08z"></path>
        </g>
      </svg>
    </SvgIcon>
  );
}

function BulletIcon() {
  const theme = useTheme();
  return (
    <SvgIcon>
      <svg
        width="140px"
        height="140px"
        style={{fill: theme.palette.mode === "dark" ? "white" : "black"}}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M12 10a2 2 0 1 0 0 4 2 2 0 1 0 0-4z" />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    </SvgIcon>
  );
}

function AppMenuItem(props: MenuItemProps) {
  const location = useLocation();
  const {pathname} = location;

  return (
    <ListItem key={props.menuItemDef.label} title={props.menuItemDef.label} disablePadding sx={{display: "block"}}>
      <Tooltip title={props.isMenuOpened ? "" : props.menuItemDef.label} placement="right">
        <ListItemButton
          style={{borderRadius: "8px", marginBottom: "5px"}}
          selected={pathname === props.menuItemDef.url}
          sx={{
            minHeight: 48,
            justifyContent: props.isMenuOpened ? "initial" : "center",
            px: 2.5,
          }}
          onClick={props.onClick}>
          <ListItemIcon
            style={{
              marginRight: props.isMenuOpened ? 10 : 24,
              marginLeft: props.isMenuOpened ? -10 : 20,
            }}
            sx={{
              minWidth: 0,
              mr: props.isMenuOpened ? 3 : "auto",
              justifyContent: "center",
            }}>
            {props.menuItemDef.icon}
          </ListItemIcon>
          <ListItemText primary={props.menuItemDef.label} sx={{opacity: props.isMenuOpened ? 1 : 0}} />
          {props.menuItemDef.subMenuItem.length > 0 && props.isMenuOpened && (
            <>{props.collapsable ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>
          )}
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
}

function AppMenu() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authorization = useSelector((state: RootState) => state.authorization.data);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [menuItemGraph, setMenuItemGraph] = useState<MenuItemGraph>({
    itemListSizea: [],
    itemListResource: [],
    itemListFleetManagement: [],
    itemListPlatformAdministration: [],
  });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    keycloak.logout();
  };

  useEffect(() => {
    if (authorization !== null) {
      keycloak.loadUserInfo().then((kcUserInfo: object) => {
        const infos = kcUserInfo as UserInfo;
        const localUserInfo = {} as UserInfo;
        localUserInfo.email = infos.email;
        localUserInfo.name = infos.name;
        setUserInfo(localUserInfo);
      });

      const itemListSizea: MenuItemDef[] = [
        {
          label: "Étude avant-vente",
          icon: <ViewInArIcon />,
          url: "",
          subMenuItem: [],
        },
      ];

      if (authorization.authAppImplantation) {
        itemListSizea[0].subMenuItem.push({
          label: "Implantation",
          icon: <WhereToVoteIcon />,
          url: "/implantation",
          subMenuItem: [],
        });
      }

      if (authorization.authAppSizea) {
        itemListSizea[0].subMenuItem.push({
          label: "Dimensionnement",
          icon: <ViewInArIcon />,
          url: "",
          subMenuItem: [
            {
              label: "Simulation",
              icon: <BulletIcon />,
              url: "/sizea/form",
              subMenuItem: [],
            },
            {
              label: "Historique",
              icon: <BulletIcon />,
              url: "/sizea/simulations",
              subMenuItem: [],
            },
          ],
        });
      }

      const itemListFleetManagement: MenuItemDef[] = [
        {
          label: "Gestion du parc",
          icon: <BuildIcon />,
          url: "",
          subMenuItem: [],
        },
      ];

      if (authorization.authAppFleetManagement) {
        itemListFleetManagement[0].subMenuItem.push(
          {
            label: "Clients",
            icon: <PersonIcon />,
            url: "/fleet/client",
            subMenuItem: [],
          },
          {
            label: "Installations",
            icon: <SolarPowerIcon />,
            url: "/fleet/plant",
            subMenuItem: [],
          },
        );
      }

      if (authorization.authAppInstallationControl) {
        itemListFleetManagement[0].subMenuItem.push({
          label: "Contrôle",
          icon: <ChecklistOutlinedIcon />,
          url: "",
          subMenuItem: [
            {
              label: "Mises en route",
              icon: <BulletIcon />,
              url: "/fleet/plants-to-control",
              subMenuItem: [],
            },
            {
              label: "Points de contrôle",
              icon: <BulletIcon />,
              url: "/fleet/control",
              subMenuItem: [],
            },
          ],
        });
      }

      if (authorization.authAppScada) {
        itemListFleetManagement[0].subMenuItem.push({
          label: "SCADA",
          icon: <QueryStatsIcon />,
          url: "",
          subMenuItem: [
            {
              label: "Vue parc",
              icon: <BulletIcon />,
              url: "/scada/plants",
              subMenuItem: [],
            },
            {
              label: "Vue installation",
              icon: <BulletIcon />,
              url: "/scada/plant",
              subMenuItem: [],
            },
            {
              label: "Évènements",
              icon: <BulletIcon />,
              url: "/scada/alarms",
              subMenuItem: [],
            },
          ],
        });
      }

      const itemListResource: MenuItemDef[] = [
        {
          label: "Ressources",
          icon: <AppsIcon />,
          url: "",
          subMenuItem: [],
        },
      ];

      if (authorization.authAppSoftware) {
        itemListResource[0].subMenuItem.push({
          label: "Logiciels",
          icon: <AppSettingsAltIcon />,
          url: "/resource/software",
          subMenuItem: [],
        });
        itemListResource[0].subMenuItem.push({
          label: "Documentations",
          icon: <DescriptionIcon />,
          url: "/resource/documentations",
          subMenuItem: [],
        });
      }

      const itemListPlatformAdministration: MenuItemDef[] = [
        {
          label: "Gestion des comptes",
          icon: <BuildIcon />,
          url: "",
          subMenuItem: [],
        },
      ];

      if (authorization.authAppPlatformAdministration) {
        itemListPlatformAdministration[0].subMenuItem.push({
          label: "Utilisateurs",
          icon: <SupervisorAccountIcon />,
          url: "/platform/user",
          subMenuItem: [],
        });
      }

      setMenuItemGraph({
        itemListSizea,
        itemListResource,
        itemListPlatformAdministration,
        itemListFleetManagement,
      });
    }
  }, [authorization]);

  useMemo(() => {
    const open = localStorage.getItem("drawer-open") || "1";

    switch (open) {
      case "1":
        setIsMenuOpened(true);
        break;
      case "0":
        setIsMenuOpened(false);
        break;
    }
  }, []);

  const handleDrawerOpen = () => {
    localStorage.setItem("drawer-open", "1");
    setIsMenuOpened(true);
  };

  const handleDrawerClose = () => {
    localStorage.setItem("drawer-open", "0");
    setIsMenuOpened(false);
  };

  function contentDrawer() {
    return (
      <div>
        {/* Étude avante-vente */}
        {menuItemGraph.itemListSizea.length > 0 && menuItemGraph.itemListSizea[0].subMenuItem.length > 0 && (
          <>
            <List>
              {isMobile ? (
                <></>
              ) : (
                <IconButton
                  size="small"
                  color="primary"
                  style={{
                    position: "fixed",
                    background: theme.palette.mode === "dark" ? "rgb(18, 18, 18)" : theme.palette.common.white,
                    marginTop: "-15px",
                    marginLeft: isMenuOpened ? "250px" : "68px",
                  }}
                  aria-label="open drawer"
                  onClick={isMenuOpened ? handleDrawerClose : handleDrawerOpen}
                  edge="start">
                  {isMenuOpened ? (
                    <ArrowBackIosIcon style={{paddingLeft: "4px"}} />
                  ) : (
                    <ArrowForwardIosIcon style={{paddingLeft: "4px"}} />
                  )}
                </IconButton>
              )}
              <div style={{marginBottom: "5px"}}></div>
              <MenuItemBlock
                itemList={menuItemGraph.itemListSizea}
                isMenuOpened={isMenuOpened}
                handleDrawerOpen={handleDrawerOpen}
              />
            </List>
            <Divider className="menu-divider" />
          </>
        )}
        {/* Gestion du parc */}
        {menuItemGraph.itemListFleetManagement.length > 0 &&
          menuItemGraph.itemListFleetManagement[0].subMenuItem.length > 0 && (
            <>
              <List>
                <MenuItemBlock
                  itemList={menuItemGraph.itemListFleetManagement}
                  isMenuOpened={isMenuOpened}
                  handleDrawerOpen={handleDrawerOpen}
                />
              </List>
              <Divider className="menu-divider" />
            </>
          )}
        {/* Ressources */}
        {menuItemGraph.itemListResource.length > 0 && menuItemGraph.itemListResource[0].subMenuItem.length > 0 && (
          <>
            <List>
              <MenuItemBlock
                itemList={menuItemGraph.itemListResource}
                isMenuOpened={isMenuOpened}
                handleDrawerOpen={handleDrawerOpen}
              />
            </List>
            <Divider className="menu-divider" />
          </>
        )}
        {/* Gestion des comptes */}
        {menuItemGraph.itemListPlatformAdministration.length > 0 &&
          menuItemGraph.itemListPlatformAdministration[0].subMenuItem.length > 0 && (
            <>
              <List>
                <MenuItemBlock
                  itemList={menuItemGraph.itemListPlatformAdministration}
                  isMenuOpened={isMenuOpened}
                  handleDrawerOpen={handleDrawerOpen}
                />
              </List>
            </>
          )}
        {/* Numéro de version de l'application */}
        {isMenuOpened && <AppVersion />}
      </div>
    );
  }

  return (
    <div>
      <AppBar
        position="fixed"
        open={isMenuOpened}
        style={{
          boxShadow: "none",
          width: isMobile ? "100%" : isMenuOpened ? "calc(100% - 254px)" : "100%",
        }}>
        <Toolbar
          style={{
            backgroundColor: theme.palette.mode === "dark" ? "#121212" : theme.palette.common.white,
          }}>
          {isMobile ? (
            <IconButton
              color="primary"
              style={{
                background: "rgba(53, 186, 7, 0.12)",
                marginLeft: "0px",
                marginTop: "10px",
              }}
              aria-label="open drawer"
              onClick={isMenuOpened ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 2,
              }}>
              <MenuIcon />
            </IconButton>
          ) : (
            <></>
          )}
          {!isMobile && !isMenuOpened ? (
            <img
              onClick={() => navigate("/")}
              style={{
                cursor: "pointer",
                width: "40px",
                marginTop: "5px",
                display: "block",
              }}
              src={logo}
              alt="Logo Okwind"
            />
          ) : (
            <></>
          )}
          <span
            style={{
              fontSize: "40px",
              color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
              marginLeft: isMobile ? "-5px" : isMenuOpened ? "-17px" : "15px",
            }}>
            |
          </span>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{flexGrow: 1}}
            style={{
              color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
              marginLeft: isMobile ? "10px" : isMenuOpened ? "21px" : "12px",
              marginTop: "5px",
            }}>
            Espace Partenaire
          </Typography>

          <Box sx={{flexGrow: 0}}>
            <Tooltip title="Paramètres" data-testid="settings">
              <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                <Avatar alt={userInfo?.name} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{mt: "45px"}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              <ListItem>
                <ListItemText primary={userInfo?.name} secondary={userInfo?.email} data-testid="profilInfos" />
              </ListItem>
              <Divider></Divider>
              <ListItem>
                Thème {theme.palette.mode === "dark" ? "sombre" : "clair"}&nbsp;
                <MaterialUISwitch
                  sx={{m: 1}}
                  onClick={() => {
                    dispatch(toggleTheme());
                  }}
                  checked={theme.palette.mode === "dark"}
                  data-testid="modeToogle"
                />
              </ListItem>
              <Divider style={{marginBottom: 5}}></Divider>
              <ListItem onClick={handleLogout} style={{cursor: "pointer"}} data-testid="logOutButton">
                <ListItemText>Déconnexion</ListItemText>
                <ListItemIcon style={{marginLeft: "-50px!important"}}>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
              </ListItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {isMobile ? (
        <SwipeableDrawer
          anchor="left"
          style={{zIndex: theme.zIndex.appBar + 1000, boxShadow: "none"}}
          open={isMenuOpened}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}>
          <DrawerHeader style={{paddingTop: "20px"}}>
            <div onClick={() => navigate("/")} style={{cursor: "pointer", marginRight: "25px"}}>
              <OkwindIcon />
            </div>
          </DrawerHeader>
          <div className="navbar-list">{contentDrawer()}</div>
        </SwipeableDrawer>
      ) : (
        <Drawer
          variant="permanent"
          open={isMenuOpened}
          style={{borderRight: "0px"}}
          sx={{
            "& .MuiPaper-root": {
              border: "none",
            },
          }}>
          <div className="navbar">
            <DrawerHeader style={{paddingTop: "13px"}}>
              <div onClick={() => navigate("/")} style={{cursor: "pointer", marginRight: "10px"}}>
                <OkwindIcon />
              </div>
            </DrawerHeader>

            <div className="navbar-list">{contentDrawer()}</div>
          </div>
        </Drawer>
      )}
    </div>
  );
}

export default AppMenu;
