import dayjs from "dayjs";
import {useSearchParams} from "react-router-dom";
import {StatusCodes} from "http-status-codes";
import {Plant} from "../../../../../interfaces/Plant";
import {PlantAction} from "./PlantAction";
import {useTheme} from "@mui/material/styles";
import CachedIcon from "@mui/icons-material/Cached";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FunctionsIcon from "@mui/icons-material/Functions";

import apiV2 from "../../../../../service/api/ApiV2";
import {REACT_APP_API_V2_URL} from "../../../../../config";

import ToastService from "../../../../../service/ToastService";
import ConfirmDialogService from "../../../../../service/ConfirmDialogService";

interface propsInterface {
  plant: Plant;
}

export default function PlantActions(props: propsInterface) {
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const apiV2Url = REACT_APP_API_V2_URL;
  const MIGRATION_MODE = 3;

  const startDate = dayjs(searchParams.get("start_date"), "YYYY-MM-DD");
  const endDate = dayjs(searchParams.get("end_date"), "YYYY-MM-DD");

  const handleClickPurgeCache = (): void => {
    ConfirmDialogService.warning(
      "md",
      "Purger le cache",
      "Etes-vous sur de vouloir executer cette commande ?",
      "<strong>Cette action va changer le status de l'installation.</strong>",
      () => {
        apiV2.purgeStatus(props.plant.id).then((response) => {
          if (response.status === StatusCodes.ACCEPTED) {
            ToastService.success("La commande a été lancé, elle peut prendre plusieurs secondes");
          }
        });
      },
    );
  };

  const handleClickNominalPower = (): void => {
    ConfirmDialogService.warning(
      "md",
      "Recalculer la puissance norminale",
      "Etes-vous sur de vouloir executer le recalul de la puissance de l'installation?",
      "<strong>Cette action pourrait modifier le prédictif solaire client.</strong>",
      () => {
        apiV2.nominalPowerCompute(props.plant.id).then((response) => {
          if (response.status === StatusCodes.ACCEPTED) {
            ToastService.success("Recalcul de la puissance en cours");
          }
        });
      },
    );
  };

  const handleClickGraphMigration = (): void => {
    ConfirmDialogService.warning(
      "md",
      "Recalculer la production et la consommation",
      "Etes-vous sur de vouloir recalculer les données pour la periode selectionnée?",
      "<strong>Cette action pourrait modifier les graphiques client.</strong>",
      () => {
        apiV2.graphMigration(props.plant.id, startDate.format("YYYY-MM-DD"), MIGRATION_MODE).then((response) => {
          if (response.status === StatusCodes.ACCEPTED) {
            ToastService.success("Recalcul de la production et la consommation");
          }
        });
      },
    );
  };

  const isGraphMigrationDisabled = (): boolean => {
    return endDate.format("YYYY-MM-DD") !== startDate.format("YYYY-MM-DD");
  };

  const handleRawConsumptionData = (): void => {
    window.open(
      `${apiV2Url}/v2/admin/display/consumption/${props.plant.id}/${startDate.format("YYYY-MM-DD")}`,
      "_blank",
    );
  };

  return (
    <div>
      <h4>Actions de migration</h4>

      <PlantAction
        label="Purger le cache"
        btnTitle="Executer"
        handleClick={handleClickPurgeCache}
        icon={<CachedIcon />}></PlantAction>

      <PlantAction
        label="Recalculer la production et la consommation"
        btnTitle="Calculer"
        disabled={isGraphMigrationDisabled()}
        handleClick={handleClickGraphMigration}
        icon={<FunctionsIcon />}></PlantAction>
      {isGraphMigrationDisabled() && (
        <span
          style={{
            fontStyle: "italic",
            color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.secondaryFont,
          }}>
          Vous devez selectionner une unique journée. <br></br> (période actuelle : {startDate.format("YYYY-MM-DD")} -{" "}
          {endDate.format("YYYY-MM-DD")})
        </span>
      )}

      <PlantAction
        label="Recalculer la puissance nominale"
        btnTitle="Calculer"
        handleClick={handleClickNominalPower}
        icon={<FunctionsIcon />}></PlantAction>

      <PlantAction
        label="Données de consommation brute"
        btnTitle="Ouvrir"
        handleClick={handleRawConsumptionData}
        icon={<OpenInNewIcon />}></PlantAction>
    </div>
  );
}
