import AlarmTypeDescriptionEnum from "../enums/AlarmTypeDescriptionEnum";
import AlarmTypeEnum from "../enums/AlarmTypeEnum";

export function formatAlarmLevel(level: number): string {
  if (level >= 30) return "Alarme critique";
  if (level >= 20) return "Alarme";
  if (level >= 10) return "Fonctionnement";
  return "Info";
}

const referencesPrefix = ["TRACKER", "IMMERSION_HEATER", "PLANT", "METER", "ESS"];

const regex = new RegExp(`(${referencesPrefix.join("|")})_`, "g");
export function formatAlarmReference(alarmReference: string): string {
  return alarmReference.replace(regex, "");
}

export function formatAlarmType(alarmType: string): string | null {
  if (alarmType in AlarmTypeEnum) {
    return AlarmTypeDescriptionEnum[alarmType as keyof typeof AlarmTypeEnum];
  }
  return null;
}
