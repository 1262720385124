import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {Box, FormGroup, Grid, InputLabel, List, ListItem, TextField, Tooltip} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Simulation from "../model";
import AppButton from "../../../../component/atom/button/AppButton";
import AppFormLine from "./SizeaFormLine";
import SizeaRemoveButton from "./SizeaRemoveButton";
import SizeaNoFormLine from "./SizeaNoFormLine";
import {TrackerType} from "../../../../interfaces/TrackerType";
import {SelectTrackerType} from "../component/SelectTrackerType";
import AppTrackerIcon from "../../../../component/atom/icon/AppTrackerIcon";
import {convertKilo} from "../../../../utils/utils";

type PropsType = {
  watchFieldArray: any[];
  control: Control<Simulation, any>;
  register: UseFormRegister<Simulation>;
  setValue: UseFormSetValue<Simulation>;
  watch: UseFormWatch<Simulation>;
  errors: FieldErrors<Simulation>;
  fields: FieldArrayWithId<Simulation, "productionInput.trackerInputs", "id">[];
  append: UseFieldArrayAppend<Simulation, "productionInput.trackerInputs">;
  remove: UseFieldArrayRemove;
};

export default function SizeaFormTrackers(props: PropsType) {
  const controlledFields = props.fields.map((fieldsHotWaterRequirements, index) => {
    return {
      ...fieldsHotWaterRequirements,
      ...props.watchFieldArray[index],
    };
  });

  const handleTrackerChange = function (trackerType: TrackerType, index: number) {
    const floatingRatedPower = convertKilo(trackerType.defaultRatedPower, 3);
    props.setValue(`productionInput.trackerInputs.${index}.customRatedPower`, floatingRatedPower);
  };

  return (
    <>
      {props.fields.length === 0 && (
        <SizeaNoFormLine logo={<AppTrackerIcon />} label="Aucun tracker pour cette étude"></SizeaNoFormLine>
      )}
      <List>
        {controlledFields.map((item, index) => {
          return (
            <AppFormLine key={item.id} logo={<AppTrackerIcon />}>
              <ListItem>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={2}>
                    <FormGroup>
                      <TextField
                        type="number"
                        required
                        error={!!props.errors.productionInput?.trackerInputs?.[index]?.trackerQuantity}
                        label="Nbr tracker"
                        defaultValue={item.trackerQuantity}
                        {...props.register(`productionInput.trackerInputs.${index}.trackerQuantity` as const, {
                          required: true,
                          valueAsNumber: true,
                        })}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel required id="tracker-type-label">
                        Ref tracker
                      </InputLabel>
                      <SelectTrackerType
                        labelId="tracker-type-label"
                        label={"Ref tracker"} // masqué, mais prépare une place quand le label est shrinked, et évite que la ligne passe à travers du label
                        error={!!props.errors.productionInput?.trackerInputs?.[index]?.trackerTypeId}
                        name={`productionInput.trackerInputs.${index}.trackerTypeId`}
                        register={props.register}
                        defaultValue={item.trackerTypeId}
                        onTrackerChange={(trackerType: TrackerType) => handleTrackerChange(trackerType, index)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <Tooltip
                      title={
                        <Box>
                          <Box>
                            La puissance crête nominale peut parfois être supérieure à celle par défaut selon le type
                            des panneaux approvisionnés.
                          </Box>
                          <Box sx={{marginTop: 2}}>
                            Avant d’ajuster cette valeur vous-même, assurez-vous que les panneaux souhaités pourront
                            bien être fournis au client à la date cible. Il est de votre responsabilité de fournir une
                            étude avec des informations fiables.
                          </Box>
                        </Box>
                      }>
                      <FormGroup>
                        <TextField
                          type="number"
                          required
                          error={!!props.errors.productionInput?.trackerInputs?.[index]?.customRatedPower}
                          label="Puissance crête (kW)"
                          defaultValue={item.customRatedPower}
                          inputProps={{
                            step: "0.1",
                          }}
                          {...props.register(`productionInput.trackerInputs.${index}.customRatedPower` as const, {
                            required: true,
                            valueAsNumber: true,
                          })}
                        />
                      </FormGroup>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <SizeaRemoveButton id={`removeTracker-${index}`} onClick={() => props.remove(index)} />
                </Grid>
              </ListItem>
            </AppFormLine>
          );
        })}
      </List>
      <Box
        sx={{
          textAlign: "center",
        }}>
        <AppButton
          id={"addTrackerButton"}
          title="Ajouter une unité de production"
          onClick={() => {
            props.append({
              trackerQuantity: 1,
              trackerTypeId: "",
              customRatedPower: 0,
            });
          }}
          style="primary"
          label="Ajouter un tracker"
        />
      </Box>
    </>
  );
}
