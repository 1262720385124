import {ReactNode, useEffect, useState} from "react";
import {Plant} from "../../../../../interfaces/Plant";
import {Trace, Information} from "../../../../../interfaces/trace/Trace";
import apiTrace from "../../../../../service/api/ApiTrace";
import {formatDateTime} from "../../../../../service/FormatDateTime";
import AppCustomWidthTooltip from "../../../../../component/AppCustomWidthTooltip";
import TraceOriginEnum from "../../../../../enums/TraceOriginEnum";
import TraceActionEnum from "../../../../../enums/TraceActionEnum";
import translateToHuman from "../../../../../service/TraceActionService";
import ToastService from "../../../../../service/ToastService";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Box, Typography} from "@mui/material";
import {frFR} from "@mui/x-data-grid/locales";

interface PropsInterface {
  plant: Plant;
}

function tooltipInformation(value: string): ReactNode {
  return (
    <Box width="350px">
      <Typography variant="body2">
        <pre>{value}</pre>
      </Typography>
    </Box>
  );
}

function furtherTraceInfo(type: string, information: Information): ReactNode {
  return (
    <AppCustomWidthTooltip placement={"right"} title={<>{tooltipInformation(translateToHuman(information))}</>}>
      <span>{translateToHuman(information, type)}</span>
    </AppCustomWidthTooltip>
  );
}

export default function PlantTraces(props: PropsInterface) {
  const [traces, setTraces] = useState<Trace[]>([]);

  useEffect(() => {
    apiTrace
      .traces(props.plant.id)
      .then((traces) => {
        setTraces(traces);
      })
      .catch(() => ToastService.error("Vous n'êtes pas autorisé à accéder à l'historique."));
  }, [props.plant.id]);

  const rows = traces.map((trace, i) => {
    return {...trace, id: i};
  });
  const columns: GridColDef<Trace>[] = [
    {
      field: "date",
      headerName: "Date",
      width: 110,
      renderCell: (params) => {
        return formatDateTime(params.row.date);
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => (
        <span
          title={TraceActionEnum[params.row.action as keyof typeof TraceActionEnum]}
          style={{overflow: "hidden", textOverflow: "ellipsis"}}>
          {TraceActionEnum[params.row.action as keyof typeof TraceActionEnum]}
        </span>
      ),
    },
    {
      field: "actor",
      headerName: "Utilisateur",
      width: 170,
      renderCell: (params) => {
        return params.row.actor_type !== "USER" ? (
          params.row.actor_type + params.row.actor
        ) : (
          <a href={`mailto:${params.row.actor}`}>{params.row.actor}</a>
        );
      },
    },
    {
      field: "origin",
      headerName: "Origine",
      width: 150,
      renderCell: (params) => {
        return (
          <span
            style={{overflow: "hidden", textOverflow: "ellipsis"}}
            title={TraceOriginEnum[params.row.origin as keyof typeof TraceOriginEnum]}>
            {TraceOriginEnum[params.row.origin as keyof typeof TraceOriginEnum]}
          </span>
        );
      },
    },
    {
      field: "info",
      headerName: "Information",
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{overflow: "hidden", textOverflow: "ellipsis"}}>
            {furtherTraceInfo(params.row.action, params.row.information)}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div
        style={{
          height: "100%",
          maxHeight: "100%",
          overflow: "auto",
        }}>
        <DataGrid
          style={{height: "100%", maxHeight: "100%"}}
          rows={rows}
          columnHeaderHeight={40}
          rowHeight={40}
          columns={columns}
          rowSelection={false}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText} // FIXME : To remove when i18n will be implemented in app
        />
      </div>
    </>
  );
}
