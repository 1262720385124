import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useTheme, alpha} from "@mui/material";
import {ChartOptions, ChartData} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import {CrosshairPlugin} from "../../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair";
import {ChartDataset} from "chart.js";
import {Line} from "react-chartjs-2";
import {SoutirageData} from "../../../../../interfaces/control/Checkpoint";
import {Plant} from "../../../../../interfaces/Plant";

type SoutirageChartProps = {
  plant: Plant;
  data: SoutirageData[];
};

function convert_w_to_kw(power: number | null) {
  if (power === null) return null;
  return Math.round((power / 1000) * 100) / 100; // two digits after comma
}

export function SoutirageChart(props: SoutirageChartProps) {
  const theme = useTheme();

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      tooltip: {
        intersect: false,
        mode: "index",
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Soutirage Enedis et Okwind",
      },
      crosshair: {
        line: {
          color: theme.palette.primary.main,
          width: 1,
        },
        sync: {
          enabled: true,
          group: 1,
        },
        zoom: {
          zoomboxBackgroundColor: alpha(theme.palette.primary.main, 0.3),
          zoomboxBorderColor: alpha(theme.palette.primary.main, 0.3),
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          tooltipFormat: "dddd D MMM YYYY, HH:mm",
          displayFormats: {
            hour: "HH:mm",
            minute: "mm",
            second: "ss",
          },
        },
        ticks: {
          major: {
            enabled: true,
          },
          font: (context) => {
            return {
              weight: context.tick?.major ? "bold" : null,
              size: context.tick?.major ? 12 : 10,
              visible: false,
            };
          },
        },
      },
      y: {
        afterFit(scale) {
          scale.width = 50;
        },
        title: {
          display: true,
          text: "Puissance (kW)",
        },
      },
    },
  };

  const [chartData, setChartData] = useState<ChartData<"line">>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    async function updateDatasets(datasets: ChartDataset<"line">[]): Promise<void> {
      datasets.push({
        label: "Soutirage Enedis",
        data: props.data.map((x) => convert_w_to_kw(x.soutirage_enedis)),
        borderColor: theme.palette.soutirageEnedisCurve,
        backgroundColor: theme.palette.soutirageEnedisCurve,
      });
      datasets.push({
        label: "Soutirage Okwind",
        data: props.data.map((x) => convert_w_to_kw(x.soutirage_okwind)),
        borderColor: theme.palette.plantConsumptionCurve,
        backgroundColor: theme.palette.plantConsumptionCurve,
      });
      datasets.push({
        label: "Production",
        data: props.data.map((x) => convert_w_to_kw(x.production)),
        borderColor: theme.palette.plantProductionCurve,
        backgroundColor: theme.palette.plantProductionCurve,
      });
      setChartData({
        labels: props.data.map((x) => dayjs(x.date).tz(props.plant.timezone).format("YYYY-MM-DD HH:mm:ss")),
        datasets: datasets,
      });
    }
    const datasets: ChartDataset<"line">[] = [];
    updateDatasets(datasets);
  }, [props, theme]);
  return (
    <div className="scada-chart" style={{height: 400}}>
      {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
      <Line options={options} data={chartData} plugins={[CrosshairPlugin]} />
    </div>
  );
}
