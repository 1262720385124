import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {Dayjs} from "dayjs";
import {Direction} from "../../../constants/Direction";
import {IconButton} from "@mui/material";

type ChangeDateArrowProps = {
  id: string;
  dates: [Dayjs, Dayjs] | null;
  direction: Direction;
  onChange: (dates: [Dayjs, Dayjs]) => void;
};

function changeDates(dates: [Dayjs, Dayjs], direction: Direction): [Dayjs, Dayjs] {
  const deltaDates = dates[1].diff(dates[0], "day") + 1;
  let startDate: Dayjs;
  let endDate: Dayjs;
  if (direction === "left") {
    startDate = dates[0].subtract(deltaDates, "day");
    endDate = dates[1].subtract(deltaDates, "day");
  } else {
    startDate = dates[0].add(deltaDates, "day");
    endDate = dates[1].add(deltaDates, "day");
  }
  return [startDate, endDate];
}

export default function ChangeDatesArrow(props: ChangeDateArrowProps) {
  const arrow = props.direction === "left" ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />;

  function toggleArrow(): void {
    if (props.dates) props.onChange?.(changeDates(props.dates, props.direction));
  }

  return (
    <IconButton id={props.id} onClick={toggleArrow}>
      {arrow}
    </IconButton>
  );
}
