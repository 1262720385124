import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {Agency} from "../../interfaces/Agency";
import apiV2 from "../../service/api/ApiV2";

interface AgenciesState {
  data: Agency[];
  loading: boolean;
  error: string | null;
}

const initialState: AgenciesState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchAgencies = createAsyncThunk("agencies/fetchAgencies", async () => {
  return apiV2.agencies();
});

const agenciesSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgencies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAgencies.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAgencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default agenciesSlice.reducer;
