import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {CriteriaValueReponse} from "./model";

interface PropsInterface {
  valid: boolean;
  label: string;
  values: CriteriaValueReponse[];
}

const parameterTranslations: {[key: string]: string} = {
  qp: "Valeur du coefficient QP",
  elevation: "Altitude du site",
  distance_to_coast: "Distance de la côte",
  sk: "Valeur du coefficient SK",
  sad: "Valeur du coefficient SAD",
};

const getParameterTranslation = (parameterName: string): string => {
  return parameterTranslations[parameterName] || parameterName;
};

const formatValueWithUnit = (value: string | number, unit?: string): string => {
  if (!unit) return value.toString();
  return `${value} ${unit}`;
};

export default function Criterion(props: PropsInterface): React.ReactElement {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 1,
          mb: 1,
          mt: 3,
        }}>
        {props.valid && <DoneIcon color={"success"} fontSize={"large"} />}
        {!props.valid && <CloseIcon color={"error"} fontSize={"large"} />}
        <Typography variant="h6" fontWeight={"bold"}>
          {props.label}
        </Typography>
      </Box>

      <Box
        sx={{
          pl: {xs: 0, sm: 5}, // Padding left: 0 sur mobile, 32px (4 * 8px) sur tablette et plus
        }}>
        {props.values.map((criteriaValue, index) => {
          const translatedName = getParameterTranslation(criteriaValue.parameter_name);
          const formattedValue = formatValueWithUnit(criteriaValue.value, criteriaValue.unit);

          return (
            <Typography key={index} variant="subtitle1" gutterBottom>
              {translatedName}: {formattedValue}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
}
