import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";

export interface AlarmSelectedState {
  value: string | null;
  open: boolean; // is popup opened
}

const initialState: AlarmSelectedState = {
  value: null,
  open: false,
};

export const alarmSelectedSlice = createSlice({
  name: "AlarmSelected",
  initialState,
  reducers: {
    setAlarmSelected: (state, action) => {
      state.value = action.payload;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const {setAlarmSelected, setOpen} = alarmSelectedSlice.actions;

export const selectAlarmSelected = (state: RootState) => state.alarmSelected.value;

export default alarmSelectedSlice.reducer;
