import * as React from "react";
import {TextField} from "@mui/material";

type GpsInputPropsType = {
  label: string;
  onChange: CallableFunction;
  error: boolean;
  name: string;
  value: string | number;
};

export default function GpsInput(props: GpsInputPropsType): React.JSX.Element {
  const change = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;

    let numberValue = Number(value);
    const index = value.indexOf(".");
    if (index !== -1) {
      const result = value.slice(0, index + 4);
      numberValue = Number(result);
    }

    props.onChange(numberValue);
  };

  return (
    <TextField
      required
      type="number"
      inputProps={{
        step: "0.001",
      }}
      error={props.error}
      name={props.name}
      label={props.label}
      value={props.value}
      helperText="3 chiffres après la virgule"
      onChange={change}
    />
  );
}
