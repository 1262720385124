import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LoadService from "./LoadService";

function Loader() {
  const [open, setOpen] = React.useState<boolean>(false);

  LoadService.subject().subscribe({
    next: (open) => {
      setOpen(open);
    },
  });

  return (
    <Backdrop sx={{color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1}} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loader;
