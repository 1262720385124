import React, {useRef, useState, useEffect} from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";

import type {DialogProps} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {ClientSearch} from "../../../interfaces/client/ClientSearch";

import CloseIcon from "@mui/icons-material/Close";
import TransitionSlideUp from "../../../service/transition/TransitionSlideUp";

import ClientForm from "./ClientForm";

type DialogClientProps = {
  id: number;
  title: string;
  actionLabel: string;
  contentText?: string;
  open: boolean;
  clientSearchUpdated: ClientSearch | null;
  handleUpdateRowClient: (client: ClientSearch) => void;
  handleClose: () => void;
};

export default function DialogUser(props: DialogClientProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  type UserHandle = React.ElementRef<typeof ClientForm>;

  const formUserRef = useRef<UserHandle>(null);
  const [isValidInput, setIsValidInput] = useState<boolean>(false);

  const handleAction = () => {
    if (formUserRef && formUserRef.current) {
      formUserRef.current.submitForm();
    }
  };

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    props.handleClose();
  };

  useEffect(() => {
    if (isValidInput) {
      setTimeout(() => {
        props.handleClose();
        setIsValidInput(false);
      }, 500);
    }
  }, [isValidInput, props]);

  return (
    <Dialog
      fullScreen={isMobile}
      open={props.open}
      onClose={handleClose}
      style={{borderRadius: 22}}
      TransitionComponent={TransitionSlideUp}>
      <DialogTitle style={{padding: 0}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            {props.title}
          </Typography>
          <IconButton
            color="secondary"
            style={{background: "rgba(53, 186, 7, 0.12)"}}
            aria-label="close dialog"
            edge="start"
            onClick={props.handleClose}>
            <CloseIcon
              style={{
                color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
              }}
            />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider style={{margin: 0}}></Divider>
      <DialogContent>
        <DialogContentText style={{marginBottom: 20}}>{props.contentText}</DialogContentText>
        <ClientForm
          formRef={formUserRef}
          setIsValidInput={setIsValidInput}
          clientSearchUpdated={props.clientSearchUpdated}
          handleUpdateRowClient={props.handleUpdateRowClient}
        />
      </DialogContent>
      <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
        <Button variant="outlined" color="inherit" onClick={props.handleClose}>
          Annuler
        </Button>
        <Button id="actionClientButtonForm" variant="outlined" onClick={handleAction}>
          {" "}
          {props.actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
