import AppButton from "../../../component/atom/button/AppButton";
import {ComponentProps} from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

type Props = ComponentProps<typeof AppButton>;

export default function AppHelpButton(props: Props) {
  const {style = "tertiary"} = props;

  return <AppButton style={style} icon={<QuestionMarkIcon />} {...props} />;
}
