enum TraceActionEnum {
  "UPDATE_RESTRICTED_POWER" = "Bridage activé/désactivé",
  "UPDATE_RESTRICTED_VALUE" = "Valeur de bridage (W)",
  "UPDATE_ENERGY_CALCULATION_METHOD" = "Mode de calcul",
  "UPDATE_CONSUMPTION_LEVEL" = "Mesure de consommation",
  "UPDATE_CAN_GENERATE_API_KEY" = "Option API client",
  "CREATE_API_KEY" = "Création d'une clé d'API",
}

export default TraceActionEnum;
