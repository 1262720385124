import React, {useEffect, useState} from "react";
import apiV2 from "../../service/api/ApiV2";
import {PlatformTypeEnum} from "../../interfaces/client/PlatformTypeEnum";
import avatarOkwind from "../../assets/plant/default_photo_okwind.png";
import avatarLumioo from "../../assets/plant/default_photo_lumioo.png";
import {Plant} from "../../interfaces/Plant";
import Avatar from "@mui/material/Avatar";
import {Grid, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";

type PlantBannerProps = {
  plant: Plant;
};

const PlantBanner = ({plant}: PlantBannerProps) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const theme = useTheme();

  useEffect(() => {
    const loadImage = async () => {
      if (plant.image) {
        try {
          const src = await apiV2.getPlantImage(plant.image.contentUrl);
          setImageSrc(src);
        } catch (e) {
          console.error(`Erreur durant le chargement de l'image de l'installation n° ${plant.id}`);
        }
      } else {
        setImageSrc(plant.platform === PlatformTypeEnum.OKWIND ? avatarOkwind : avatarLumioo);
      }
    };
    loadImage();
  }, [plant.image]);

  return (
    <Grid container gap={2} direction={{xs: "column", md: "row"}} style={{marginTop: "20px"}}>
      <Grid item>
        <Avatar alt="Plant avatar" src={imageSrc} sx={{width: 50, height: 50}} />
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container direction={"column"} justifyContent={"center"} style={{height: "100%"}}>
          <Typography variant={"h6"}>{plant.aliasInstallation}</Typography>
          <div
            style={{
              color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.secondaryFont,
            }}>
            {plant.plantAddress}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlantBanner;
