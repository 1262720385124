// rollback linter legend tooltip chartjs event error
export function mergeObjectsRecursive<T extends Record<string, any>>(obj1: T, obj2: T): T {
  const merged: T = {} as T;
  for (const prop in obj1) {
    if (prop in obj2) {
      if (typeof obj1[prop] === "object" && typeof obj2[prop] === "object") {
        merged[prop] = mergeObjectsRecursive(obj1[prop], obj2[prop]);
      } else {
        merged[prop] = obj2[prop];
      }
    } else {
      merged[prop] = obj1[prop];
    }
  }
  for (const prop in obj2) {
    if (!(prop in obj1)) {
      merged[prop] = obj2[prop];
    }
  }
  return merged;
}

export function convertKilo(number: number, digits: number): number {
  const divisor = Math.pow(10, digits);
  return Math.round((number / 1000) * divisor) / divisor;
}
