import React, {useState, useEffect} from "react";
import {Collapse, Alert, IconButton, AlertProps} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ClosablePersistentAlertProps extends AlertProps {
  localStorageKey: string;
}

const ClosablePersistentAlert: React.FC<ClosablePersistentAlertProps> = (props) => {
  const {localStorageKey, children, ...alertProps} = props;
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const storedValue = localStorage.getItem(props.localStorageKey);
    if (storedValue === "false") {
      setIsOpen(false);
    }
  }, [localStorageKey]);

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem(localStorageKey, "false");
  };

  return (
    <Collapse in={isOpen}>
      <Alert
        {...alertProps}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
        {children}
      </Alert>
    </Collapse>
  );
};

export default ClosablePersistentAlert;
