import apiV2 from "../../service/api/ApiV2";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Tag from "../../interfaces/Tag";

type PlantTagsState = {
  data: Tag[];
  loading: boolean;
  error: string | null;
};

const initialState: PlantTagsState = {
  data: [],
  loading: false,
  error: null,
};

export const getPlantTags = createAsyncThunk("plantTags/getPlantTags", async (search?: string) => {
  return await apiV2.getPlantTags(search);
});

const plantTagsSlice = createSlice({
  name: "plantTags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlantTags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlantTags.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPlantTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred while retrieving plant tags";
        state.data = [];
      });
  },
});

export default plantTagsSlice.reducer;
