import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import CardRow from "../CardRow";
import apiV2 from "../../../../../service/api/ApiV2";
import {Plant} from "../../../../../interfaces/Plant";
import {ClientSearch} from "../../../../../interfaces/client/ClientSearch";
import {Divider} from "@mui/material";

type PlantCustomerProps = {
  plant: Plant;
};

const PlantCustomer = ({plant}: PlantCustomerProps) => {
  const [customer, setCustomer] = useState<ClientSearch | null>(null);
  const [customers, setCustomers] = useState<ClientSearch[]>([]);

  // Récupération des informations du client
  useEffect(() => {
    apiV2.searchPlantById(plant.id).then((data) => {
      if (data.length === 0) return;
      if (data[0].clients.length === 0) return;
      setCustomer(data[0].clients[0]);
      setCustomers(data[0].clients.slice(1));
    });
  }, [plant]);

  if (!customer) {
    return <>Aucun client pour le site</>;
  }
  return (
    <div>
      <h4>Information client</h4>
      <CardRow label="Prénom NOM (Société)">
        <Typography component="span" color="text.primary">
          {`${customer?.firstName} ${customer?.lastName.toUpperCase()}`}
        </Typography>
        {customer?.aliasCompany ? (
          <Typography sx={{display: "inline"}} component="span" variant="body2" color="primary">
            &nbsp;({customer.aliasCompany})
          </Typography>
        ) : (
          ""
        )}
      </CardRow>

      <CardRow label="Type">{plant.platform.toUpperCase()}</CardRow>
      <CardRow label="Adresse">{customer?.address ? customer.address : ""}</CardRow>
      <CardRow label="Email">
        <a href={`mailto:${customer?.email ? customer.email : ""}`}>{customer?.email ? customer.email : "-"}</a>
      </CardRow>
      <CardRow label="Téléphone">{customer?.phone ? customer.phone : "-"}</CardRow>

      <CardRow label="Notification par email">{customer?.notifiedByEmail ? "Oui" : "Non"}</CardRow>
      <CardRow label="Notification par sms">{customer?.notifiedBySms ? "Oui" : "Non"}</CardRow>

      <div style={{marginTop: "20px"}}>
        <Divider></Divider>
      </div>

      {customers.length > 0 && (
        <>
          <h4>Utilisateurs secondaires ({customers.length})</h4>
          {customers.map((currentCustomer) => (
            <CardRow label="Prénom Nom">{`${currentCustomer.firstName} ${currentCustomer.lastName.toUpperCase()}`}</CardRow>
          ))}
        </>
      )}
      {customers.length === 0 && <h4>Aucun utilisateur secondaire</h4>}
    </div>
  );
};

export default PlantCustomer;
