import {Upload} from "antd";
import {UploadChangeParam} from "antd/es/upload";
import {UploadRequestOption} from "rc-upload/lib/interface";
import {useAppDispatch} from "../../../../../store/hooks";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {fetchCheckpointExecuteSoutirage} from "../../../../../store/control/checkpointsBlock.store";
import {useSearchParams} from "react-router-dom";
import {LoadingButton} from "@mui/lab";

type UploadEnedisFileProps = {
  isLoading: boolean;
};

export default function UploadEnedisFile(props: UploadEnedisFileProps) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  function changeFile(param: UploadChangeParam) {
    const file = param.fileList.length > 0 ? param.fileList[0] : null; // do not use param.file property because it's not set to null when removing file
    const plantId = searchParams.get("plant_id");
    if (plantId && file?.status === "done") {
      dispatch(fetchCheckpointExecuteSoutirage([parseInt(plantId), file]));
    }
  }

  // to avoid the automatic post request of file
  const dummyRequest = (option: UploadRequestOption) => {
    setTimeout(() => {
      option.onSuccess!("ok");
    }, 0);
  };

  return (
    <Upload id="uploadEnedisFile" onChange={changeFile} customRequest={dummyRequest} maxCount={1} accept=".xlsx, .csv">
      <LoadingButton loading={props.isLoading} variant="outlined" startIcon={<CloudUploadIcon color="primary" />}>
        Fichier Enedis
      </LoadingButton>
    </Upload>
  );
}
