import React, {ForwardRefRenderFunction, RefObject, useImperativeHandle, useState} from "react";
import {ReactNode} from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";

import CircularProgress from "@mui/material/CircularProgress";
import ClipBoardButton from "../../../../service/ClipboardService";

type CardRowImperativeHandler = {
  setIsEditing: (value: boolean) => void;
};

type CardRowProps = {
  label: string;
  children: ReactNode;
  isEditable?: boolean;
  isCopyEnable?: boolean;
  childrenEdit?: ReactNode;
  chidrenEditValidate?: () => void;
  cardRef?: RefObject<CardRowImperativeHandler>;
  tooltipTitle?: string;
};

const CardRow: ForwardRefRenderFunction<CardRowImperativeHandler, CardRowProps> = (props: CardRowProps) => {
  const trackers = useSelector((state: RootState) => state.trackers);
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleValidate = () => {
    if (props.chidrenEditValidate) {
      props.chidrenEditValidate();
      setIsEditing(false);
    }
  };

  //permet d appeler setIsEditing depuis le composant parent
  useImperativeHandle(props.cardRef, () => ({
    setIsEditing(value: boolean) {
      setIsEditing(value);
    },
  }));

  return (
    <Grid container sx={{mt: "10px"}}>
      <Grid item xs={4} md={6} className="item-feature">
        {props.tooltipTitle && props.tooltipTitle !== "" ? (
          <div>
            <Tooltip title={props.tooltipTitle}>
              <p style={{marginTop: "0px", marginBottom: "0px"}}>{props.label}</p>
            </Tooltip>
          </div>
        ) : (
          <p style={{marginTop: "0px", marginBottom: "0px"}}>{props.label}</p>
        )}
      </Grid>
      <Grid item xs={6} md={5} style={{display: "flex", alignItems: "center"}}>
        {props.isEditable && isEditing ? (
          props.childrenEdit
        ) : (
          <span style={{display: "flex", alignItems: "center", maxWidth: "100%"}}>{props.children}</span>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        md={1}
        style={{
          margin: "auto",
        }}>
        {props.isEditable && !isEditing && (
          <IconButton
            style={{marginTop: "-2px", padding: "0px"}}
            color="secondary"
            edge="start"
            size="small"
            onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        )}
        {props.isCopyEnable && <ClipBoardButton text={props.children?.toString()}></ClipBoardButton>}
        {props.isEditable && isEditing && (
          <Box>
            <Grid container>
              <Grid item xs={6} style={{padding: "5px"}}>
                <Tooltip title="Valider la modification">
                  <IconButton
                    style={{padding: "0px"}}
                    color="primary"
                    edge="start"
                    size="small"
                    onClick={handleValidate}>
                    {trackers.loading ? <CircularProgress style={{marginRight: "5px"}} size={22} /> : <DoneIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6} style={{padding: "5px"}}>
                <Tooltip title="Annuler la modification">
                  <IconButton
                    style={{padding: "0px"}}
                    edge="start"
                    size="small"
                    onClick={() => {
                      setIsEditing(false);
                    }}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default React.forwardRef(CardRow);
