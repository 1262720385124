import React, {useState} from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";

import {DialogProps, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from "@mui/icons-material/Close";
import TransitionSlideUp from "../../../../../service/transition/TransitionSlideUp";

import meter from "../../../../../assets/meter/carlo.png";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

import {Meter} from "../../../../../interfaces/Meter";
import MeterGeneral from "./MeterGeneral";
import MeterSetting from "./MeterSetting";

type MeterDialogProps = {
  meter: Meter;
  open: boolean;
  handleClose: () => void;
};

export default function MeterDialog(props: MeterDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [maxWidth] = useState<DialogProps["maxWidth"]>("md");

  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    props.handleClose();
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullScreen={isMobile}
      open={props.open}
      onClose={handleClose}
      style={{borderRadius: 22}}
      TransitionComponent={TransitionSlideUp}>
      <DialogTitle style={{padding: 0}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            Compteur: {props.meter.type.deviceDescription}
          </Typography>
          <IconButton
            color="secondary"
            style={{background: "rgba(53, 186, 7, 0.12)"}}
            aria-label="close dialog"
            edge="start"
            onClick={props.handleClose}>
            <CloseIcon
              style={{
                color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
              }}
            />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider style={{margin: 0}}></Divider>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={5}>
            <img alt="immersion heater" style={{width: "90%", marginTop: "10px"}} src={meter} />
          </Grid>
          <Grid item xs={12} md={7} style={{height: "250px", padding: "0px"}}>
            <TabContext value={value}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs tracker">
                <Tab label="Informations" value="1" />
                <Tab label="Paramètres" value="2" />
              </Tabs>
              <TabPanel value="1" style={{padding: "10px"}}>
                <MeterGeneral meter={props.meter}></MeterGeneral>
              </TabPanel>
              <TabPanel value="2" style={{padding: "10px"}}>
                <MeterSetting meter={props.meter}></MeterSetting>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
        <Button variant="outlined" color="inherit" onClick={props.handleClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
