import {Alert, AlertColor, AlertTitle} from "@mui/material";
import {Alarm} from "../../../../interfaces/Alarm";
import {useSearchParams} from "react-router-dom";
import dayjs from "dayjs";

type AlarmDetailProps = {
  alarm: Alarm;
};

const getSeverityFromLevel = (level: number): AlertColor => {
  if (level >= 30) return "error";
  if (level >= 20) return "warning";
  if (level >= 10) return "success";
  return "info";
};

export const AlarmDetail = (props: AlarmDetailProps) => {
  const dateFormated = dayjs(props.alarm.begin);
  const [searchParams, setSearchParams] = useSearchParams();

  const openScadaOnAlarmBeginDate = (): void => {
    searchParams.set("start_date", dateFormated.subtract(1, "day").format("YYYY-MM-DD"));
    searchParams.set("end_date", dateFormated.add(1, "day").format("YYYY-MM-DD"));
    setSearchParams(searchParams);
  };

  return (
    <Alert sx={{marginTop: "10px", fontSize: "12px"}} severity={getSeverityFromLevel(props.alarm.type.level)}>
      <AlertTitle sx={{fontWeight: "bold", fontSize: "12px"}}>{props.alarm.type.label}</AlertTitle>
      <AlertTitle sx={{fontWeight: "bold", fontSize: "12px"}}>{props.alarm.type.reference}</AlertTitle>
      {props.alarm.type.savDescription}
      <br />
      <i>
        Depuis le{" "}
        <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={openScadaOnAlarmBeginDate}>
          {dateFormated.format("DD/MM/YYYY HH:mm")}
        </span>
      </i>
    </Alert>
  );
};
