import {UploadChangeParam} from "antd/es/upload/interface";
import ToastService from "../../../../service/ToastService";

export const uploadOnChange = function (param: UploadChangeParam) {
  if (param.file === undefined) return;
  if (param.file.error === undefined) return;
  if (param.file.response === undefined) return;

  // Erreur générique
  let violation = param.file.response.detail;

  // Sinon on affiche l'erreur API PLATFORM
  if (param.file.response["hydra:description"] !== undefined) {
    violation = param.file.response["hydra:description"];
  }

  ToastService.error(violation);
};
