import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {Tracker} from "../../../interfaces/Tracker";
import {Plant} from "../../../interfaces/Plant";
import {ImmersionHeater} from "../../../interfaces/ImmersionHeater";
import {Meter} from "../../../interfaces/Meter";
import {Ess} from "../../../interfaces/ess/Ess";
import apiV2 from "../../../service/api/ApiV2";
import {InterventionDate} from "../../../interfaces/plant/InterventionDate";
import ToastService from "../../../service/ToastService";
import {StatusCodes} from "http-status-codes";
import {SetPlantConsumptionLevel} from "../../../interfaces/plant/SetPlantConsumptionLevel";

export interface PlantObjects {
  plant: Plant;
  trackers: {isSelected: boolean; tracker: Tracker}[];
  immersionHeaters: {isSelected: boolean; immersionHeater: ImmersionHeater}[];
  ess: {isSelected: boolean; ess: Ess}[];
  meter?: {isSelected: boolean; meter: Meter};
}

export interface PlantObjectsState {
  value: PlantObjects | null;
}

const initialState: PlantObjectsState = {
  value: null,
};

export const updatePlantInterventionDate = createAsyncThunk(
  "plant/updatePlantInterventionDate",
  async (interventionDate: InterventionDate) => {
    const response = await apiV2.updatePlantInterventionDate(interventionDate);
    return {response, interventionDate: interventionDate};
  },
);

export const updatePlantConsumptionLevel = createAsyncThunk(
  "plant/updatePlantConsumptionLevel",
  async (consumptionLevel: SetPlantConsumptionLevel) => {
    const response = await apiV2.updatePlantConsumptionLevel(consumptionLevel);
    return {response, consumptionLevel: consumptionLevel};
  },
);

export const plantObjectsSlice = createSlice({
  name: "plantObjects",
  initialState,
  reducers: {
    toggleTracker: (state, action) => {
      if (state.value === null) return;
      const {id, isSelected} = action.payload;
      state.value.trackers = state.value?.trackers.map((x) =>
        x.tracker.id === id ? {isSelected: isSelected, tracker: x.tracker} : x,
      );
    },
    toggleImmersionHeater: (state, action) => {
      if (state.value === null) return;
      const {id, isSelected} = action.payload;
      state.value.immersionHeaters = state.value.immersionHeaters.map((x) =>
        x.immersionHeater.id === id ? {isSelected: isSelected, immersionHeater: x.immersionHeater} : x,
      );
    },
    toggleEss: (state, action) => {
      if (state.value === null) return;
      const {id, isSelected} = action.payload;
      state.value.ess = state.value.ess.map((x) => (x.ess.id === id ? {isSelected: isSelected, ess: x.ess} : x));
    },
    toggleMeter: (state, action) => {
      if (state.value === null) return;
      if (state.value.meter) state.value.meter.isSelected = action.payload.isSelected;
    },
    toggleAll: (state, action) => {
      if (state.value === null) return;
      state.value.trackers = state.value.trackers.map((x) => {
        return {tracker: x.tracker, isSelected: action.payload.isSelected};
      });
      state.value.immersionHeaters = state.value.immersionHeaters.map((x) => {
        return {
          immersionHeater: x.immersionHeater,
          isSelected: action.payload.isSelected,
        };
      });
      state.value.ess = state.value.ess.map((x) => {
        return {ess: x.ess, isSelected: action.payload.isSelected};
      });
      if (state.value.meter) state.value.meter.isSelected = action.payload.isSelected;
    },
    setPlantObjects: (state, action) => {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePlantInterventionDate.rejected, (state, action) => {
        const error = action.error.message || "Probleme lors de la mise à jour de la date d'intervention";
        ToastService.error(error);
      })
      .addCase(updatePlantInterventionDate.fulfilled, (state, action) => {
        const {response, interventionDate} = action.payload;

        if (response.status !== StatusCodes.ACCEPTED) {
          return;
        }

        if (state.value !== null) {
          state.value.plant.interventionDate = interventionDate.interventionDate;
        }
        ToastService.success("La date d'intervention a été modifiée avec succès");
      })
      .addCase(updatePlantConsumptionLevel.rejected, (state, action) => {
        const error = action.error.message || "Probleme lors de la mise à jour de la mesure de consommation";
        ToastService.error(error);
      })
      .addCase(updatePlantConsumptionLevel.fulfilled, (state, action) => {
        const {response, consumptionLevel} = action.payload;

        if (response.status !== StatusCodes.ACCEPTED) {
          return;
        }

        if (state.value !== null) {
          state.value.plant.consumptionLevel = consumptionLevel.consumptionLevel;
        }
        ToastService.success("La mesure de consommation a été modifiée avec succès");
      });
  },
});

export const {setPlantObjects, toggleTracker, toggleImmersionHeater, toggleEss, toggleMeter, toggleAll} =
  plantObjectsSlice.actions;

export const selectPlantObjects = (state: RootState) => state.plantObjects.value;

export default plantObjectsSlice.reducer;
