import {Box, Divider, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {GeneralTypeEnum, ImplantationResponse} from "./model";
import Criterion from "./Criterion";

interface PropsInterface {
  result: ImplantationResponse;
}

export default function Result(props: PropsInterface): React.ReactElement {
  const theme = useTheme();

  return (
    <Box>
      <Divider textAlign="left">
        <Typography fontWeight={"bold"} padding={1}>
          Résultat de l'étude
        </Typography>
      </Divider>
      <Typography
        fontWeight={"bold"}
        padding={1}
        sx={{
          color: props.result.general === GeneralTypeEnum.OK ? theme.palette.success.main : theme.palette.error.main,
        }}>
        {props.result.general === GeneralTypeEnum.OK && "Cet emplacement peut accueillir l'équipement sélectionné !"}
        {props.result.general !== GeneralTypeEnum.OK &&
          "Cet emplacement ne peut pas accueillir l'équipement sélectionné en raison de son dimensionnement. Veuillez envisager un autre équipement ou un meilleur emplacement."}
      </Typography>
      <Box sx={{marginBottom: 3}}>
        {props.result.wind && (
          <Criterion
            label="Vent"
            valid={props.result.wind.status === GeneralTypeEnum.OK}
            values={props.result.wind.values}
          />
        )}
        {props.result.elevation && (
          <Criterion
            label="Altitude"
            valid={props.result.elevation.status === GeneralTypeEnum.OK}
            values={props.result.elevation.values}
          />
        )}
        {props.result.distance_to_coast && (
          <Criterion
            label="Corrosion"
            valid={props.result.distance_to_coast.status === GeneralTypeEnum.OK}
            values={props.result.distance_to_coast.values}
          />
        )}
        {props.result.snow && (
          <Criterion
            label="Neige"
            valid={props.result.snow.status === GeneralTypeEnum.OK}
            values={props.result.snow.values}
          />
        )}
      </Box>
    </Box>
  );
}
