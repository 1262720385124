import {ChartData, ChartDataset, ChartOptions} from "chart.js";
import {Line} from "react-chartjs-2";
import {mergeObjectsRecursive} from "../../../../utils/utils";
import {Plant} from "../../../../interfaces/Plant";
import {Tracker} from "../../../../interfaces/Tracker";
import {Dayjs} from "dayjs";
import {useTheme} from "@mui/material";
import {useEffect, useState, useRef} from "react";
import {formatXLabels, generateDates} from "../DateService";
import apiV2 from "../../../../service/api/ApiV2";
import {fillMissingDate, removeUnnecessaryDate} from "../ParseRawData";
import {TrackerPosition} from "../../../../interfaces/TrackerPosition";
import {forkJoin} from "rxjs";
import {CrosshairPlugin} from "../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";

import GraphResizeService from "../../../../service/GraphResizeService";

type AzimuthChartProps = {
  plant: Plant;
  trackers: Tracker[];
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

const defaultOptions: ChartOptions<"line"> = {
  plugins: {
    title: {
      display: true,
      text: "Azimuth (°)",
    },
  },
};

export function AzimuthChart(props: AzimuthChartProps) {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const options = mergeObjectsRecursive(defaultOptions, props.options);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    labels: [],
    datasets: [],
  });
  const [width, setWidth] = useState(0);

  useEffect(() => {
    GraphResizeService.checkResizeWidth(ref, width, setWidth);
  }, [ref, width, setWidth]);

  useEffect(() => {
    const expectedDates = generateDates(props.startDate, props.endDate);

    async function updateDatasets(datasets: ChartDataset<"line">[]): Promise<void> {
      const nbColors = theme.palette.trackerAzimuthCurves.length;
      const tasks = [];
      for (const tracker of props.trackers) {
        if (tracker.operationDate) {
          // L'appel est en erreur 500 si pas d'opération date (plus simple de fixer le front que de corriger tous les appels api-v2)
          tasks.push(apiV2.trackerPositions(props.startDate, props.endDate, tracker.id));
        }
      }
      await Promise.all(tasks).then((results) => {
        for (let i = 0; i < results.length; i++) {
          results[i] = removeUnnecessaryDate(results[i]);
          fillMissingDate(results[i], expectedDates);
          datasets.push({
            label: `Azimuth tracker ${props.trackers[i].nTrk}`,
            data: results[i].map((x: TrackerPosition) => x.azimuth),
            borderColor: theme.palette.trackerAzimuthCurves[i % nbColors],
            backgroundColor: theme.palette.trackerAzimuthCurves[i % nbColors],
            borderWidth: 2,
          });
        }
        if (results.length > 0) {
          datasets.push({
            label: "Azimuth attendu",
            data: results[0].map((x: TrackerPosition) => x.expectedAzimuth),
            borderColor: theme.palette.trackerExpectedAzimuthCurve,
            backgroundColor: theme.palette.trackerExpectedAzimuthCurve,
            borderWidth: 2,
          });
        }
      });
    }
    const datasets: ChartDataset<"line">[] = [];
    const waitForDatasets = forkJoin({
      datasets: updateDatasets(datasets),
    });
    waitForDatasets.subscribe({
      next: () =>
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: datasets,
        }),
      error: () => {
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: [],
        });
      },
    });
  }, [props, theme]);

  const chartHeight = 180;

  return (
    <div ref={ref} className="scada-card-zoom">
      <ResizableBox
        transformScale={0.75}
        className="box"
        width={width}
        height={chartHeight}
        resizeHandles={["s"]}
        minConstraints={[100, chartHeight]}
        maxConstraints={[width, chartHeight * 4]}>
        {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
        <Line options={options} data={chartData} plugins={[CrosshairPlugin]} style={{marginLeft: "15px"}} />
      </ResizableBox>
    </div>
  );
}
