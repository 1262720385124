import {useAppDispatch} from "../../../../store/hooks";
import {Card, CardContent, Checkbox, Grid, Typography, useTheme} from "@mui/material";
import {PlantObjects, toggleAll} from "../../../../store/scada/plant/PlantObjectsSlice";

type SelectAllPlantObjectsProps = {
  plantObjects: PlantObjects;
};

export default function SelectAllPlantObjects(props: SelectAllPlantObjectsProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  function toggleAllPlantObjects(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(toggleAll({isSelected: event.target.checked}));
  }

  function areAllPlantObjectsInSameState(state: boolean): boolean {
    return (
      props.plantObjects.trackers.map((x) => x.isSelected).every((y) => y === state) &&
      props.plantObjects.immersionHeaters.map((x) => x.isSelected).every((y) => y === state) &&
      props.plantObjects.ess.map((x) => x.isSelected).every((y) => y === state)
    );
  }

  return (
    <Card className="scada-card">
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <CardContent>
            <Typography
              className="scada-card-title"
              style={{
                color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.secondaryFont,
                marginTop: "5px",
              }}>
              Selectionner tous les objets connectés
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={2} style={{paddingRight: "10px", textAlign: "right"}}>
          <Checkbox
            style={{marginTop: "-5px"}}
            checked={areAllPlantObjectsInSameState(true)}
            indeterminate={!areAllPlantObjectsInSameState(true) && !areAllPlantObjectsInSameState(false)}
            onChange={toggleAllPlantObjects}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
