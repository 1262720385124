import {Box} from "@mui/material";

interface Props {
  src: string;
}

export default function AppIcon(props: Props) {
  return (
    <Box>
      <img style={{width: "100%", height: "100%"}} srcSet={`${props.src}`} src={`${props.src}`} loading="lazy" />
    </Box>
  );
}
