import {useEffect, useState} from "react";
import apiV2 from "../../../../service/api/ApiV2";
import {Button, Card, CardContent, ListItem, Typography} from "@mui/material";
import dayjs from "dayjs";
import {List} from "antd";
import {GetAlarmNotViewedBySav} from "../../../../interfaces/GetAlarmNotViewedBySav";
import AlarmNotViewedBySavItem from "./AlarmNotViewedBySavItem";

type CardAlarmsNotViewedBySavTableProps = {
  plantId: number;
};

async function getAlarms(plantId: number): Promise<GetAlarmNotViewedBySav[]> {
  const result = await apiV2.getAlarmNotViewedBySav(plantId).then((data) => {
    data = data.map((row) => {
      row.begin = dayjs(row.begin).tz(dayjs.tz.guess()).format("DD/MM/YYYY HH:mm:ss");
      return row;
    });
    return data;
  });
  return result;
}

export default function CardPlantAlarmsNotViewedBySav(props: CardAlarmsNotViewedBySavTableProps) {
  const [alarms, setAlarms] = useState<GetAlarmNotViewedBySav[] | null>(null);

  useEffect(() => {
    getAlarms(props.plantId).then((data) => setAlarms(data));
  }, [props.plantId]);

  async function alarmClickAll(alarms: GetAlarmNotViewedBySav[]) {
    const tasks = [];
    for (const alarm of alarms) {
      tasks.push(apiV2.setAlarmSavStatusAsViewed(alarm.id));
    }
    await Promise.all(tasks).then(() => {
      getAlarms(props.plantId).then((data) => {
        setAlarms(data);
      });
    });
  }

  const clickAlarm = (alarmId: string) => {
    apiV2.setAlarmSavStatusAsViewed(alarmId).then(() => {
      getAlarms(props.plantId).then((data) => {
        setAlarms(data);
      });
    });
  };

  if (alarms === null) return <></>;
  return (
    <Card className="scada-card">
      <CardContent>
        <Typography component="div" className="scada-card-title">
          Évènements à traiter
        </Typography>
        {alarms.length > 0 && (
          <div>
            <Button
              id="alarms-treated-button"
              variant="outlined"
              color="secondary"
              style={{width: "100%", marginTop: "10px"}}
              onClick={() => alarmClickAll(alarms)}>
              Tous les évènements ont été traités
            </Button>
            <List style={{marginTop: "10px"}}>
              {alarms.map((row) => {
                return (
                  <ListItem key={row.id} disablePadding style={{width: "100%", marginTop: "5px"}}>
                    <AlarmNotViewedBySavItem alarm={row} onClick={clickAlarm} />
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
        {alarms.length === 0 && <div>Aucun évènement non traité</div>}
      </CardContent>
    </Card>
  );
}
