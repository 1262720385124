import {ImplantationRequest, ImplantationResponse} from "../../app/pre-sale/implantation-v2/model";
import {REACT_APP_API_IMPLANTATION_URL} from "../../config";
import authenticatedApi from "../AuthenticatedApi";

const baseUrl = REACT_APP_API_IMPLANTATION_URL;
const simulationEndpoint = "implantation";

export const apiImplantation = {
  simulate: async function (simulation: ImplantationRequest): Promise<ImplantationResponse> {
    const url = `${baseUrl}/${simulationEndpoint}`;

    const response = await authenticatedApi.post(url, simulation);

    if (!response.ok) {
      const errorBody = await response.json().catch(() => null);
      const errorMessage =
        errorBody && typeof errorBody === "object" && "detail" in errorBody ? errorBody.detail : response.statusText;
      throw new Error(errorMessage);
    }

    return response.json();
  },
};
