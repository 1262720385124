import apiSizea from "../../../../service/api/ApiSizea";
import {useEffect, useState} from "react";
import {SxProps} from "@mui/material";
import Simulation, {HotWaterTankTypeRef, ImmersionHeaterType} from "../model";
import {UseFormRegister, UseFormSetValue, UseControllerProps, FieldValues} from "react-hook-form";
import {SelectType} from "./SelectType";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error: boolean | undefined;
  hotwatertanktype: HotWaterTankTypeRef;
  register: UseFormRegister<Simulation>;
  sx: SxProps;
  labelId: string;
  label: string;
  setValue: UseFormSetValue<Simulation>;
}

export function SelectImmersionHeaterType<T extends FieldValues>(props: Props<T>) {
  const [immersionHeaterTypes, setImmersionHeaterTypes] = useState<ImmersionHeaterType[]>([]);

  useEffect(() => {
    apiSizea.immersionHeaterTypes(props.hotwatertanktype).then((data) => {
      setImmersionHeaterTypes(data);
    });
  }, [props.hotwatertanktype]);

  if (immersionHeaterTypes.length === 0) {
    return <></>;
  }

  return (
    <SelectType
      types={immersionHeaterTypes}
      sx={props.sx}
      error={props.error}
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
}
