import AppButton from "../../../../component/atom/button/AppButton";
import {ComponentProps} from "react";
import RemoveIcon from "@mui/icons-material/Remove";

type Props = ComponentProps<typeof AppButton>;

export default function SizeaRemoveButton(props: Props) {
  return (
    <AppButton
      icon={<RemoveIcon />}
      title="Supprimer"
      sx={{
        marginBottom: 3,
      }}
      {...props}
    />
  );
}
