import React, {ComponentProps} from "react";
import {Control, Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import AppCustomWidthTooltip from "../AppCustomWidthTooltip";

type ControlledSelectOptionProps = {
  value: number | string;
  label: string;
};

type ControlledSelectProps = {
  name: string;
  label: string;
  required?: boolean;
  tooltipLabelText?: string;
  TooltipIcon?: OverridableComponent<any>;
  control: Control<any>;
  options: ControlledSelectOptionProps[];
  style?: React.CSSProperties | undefined;
} & ComponentProps<typeof Select>;

const ControlledSelect = ({
  id,
  name,
  label,
  control,
  options,
  tooltipLabelText,
  TooltipIcon,
  size,
  variant,
  fullWidth,
  style,
  required = false,
}: ControlledSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <FormControl fullWidth={fullWidth} variant={variant} style={style}>
          <InputLabel id={`${id}-label`} error={!!error} required={required}>
            {label}
            {tooltipLabelText && (
              <AppCustomWidthTooltip title={tooltipLabelText}>
                {TooltipIcon ? <TooltipIcon style={{marginBottom: "-5px", marginLeft: "5px"}} /> : <span>Icon</span>}
              </AppCustomWidthTooltip>
            )}
          </InputLabel>
          <Select
            size={size}
            labelId={`${id}-label`}
            data-cy={`cypress-select-${name}`}
            label={label}
            id={id}
            color="primary"
            error={!!error}
            {...field}
            {...control.register(name)}>
            {options.map((option, idx) => (
              <MenuItem data-cy={`cypress-select-${name}-option-${option.value}`} key={idx} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default ControlledSelect;
