import authenticatedApi from "../AuthenticatedApi";
import {REACT_APP_API_TRACE_URL} from "../../config";
import {Trace} from "../../interfaces/trace/Trace";

const baseUrl = REACT_APP_API_TRACE_URL;

const traceEndpoint = "trace";

const apiTrace = {
  traces: async function (plantId: number): Promise<Array<Trace>> {
    const url = `${baseUrl}/${traceEndpoint}/${plantId}`;
    const response = await authenticatedApi.get(url);
    const data = response.json();
    return data;
  },
};

export default apiTrace;
