import {useSelector} from "react-redux";
import {Control, Controller, UseFormRegister} from "react-hook-form";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {RootState} from "../../store/store";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {SetPvPanel} from "../../interfaces/tracker/SetPvPanel";
import FormControl from "@mui/material/FormControl";

type SelectPvPanelTypeProps = {
  name: "pvPanelTypeId";
  label: string;
  register: UseFormRegister<SetPvPanel>;
  required: boolean;
  control: Control<SetPvPanel>;
};

const SelectPvPanelType = (props: SelectPvPanelTypeProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const pvPanelTypesList = useSelector((state: RootState) => state.pvPanelTypes.data);
  const {control, register, name, ...selectProps} = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <FormControl fullWidth>
          <InputLabel error={!!error} id="selectPvPanelTypeLabel" required={props.required}>
            {props.label}
          </InputLabel>
          <Select
            style={{maxWidth: isMobile ? 180 : "100%"}}
            size={"small"}
            {...selectProps}
            {...field}
            labelId="selectPvPanelTypeLabel"
            id="selectPvPanelType"
            color="primary"
            {...register(name, {required: props.required})}
            error={!!error}>
            {pvPanelTypesList.map((pvPanelType, index) => (
              <MenuItem data-cy={`cypressSelectPvPanelTypeOption${index}`} key={index} value={pvPanelType.id}>
                {pvPanelType.deviceDescription}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default SelectPvPanelType;
