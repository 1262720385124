import * as React from "react";
import {Button} from "@mui/material";

type AppButtonStyle = "primary" | "secondary" | "tertiary";
type AppButtonVariant = "contained" | "outlined" | "text";

type AppButtonPropsType = {
  style?: AppButtonStyle;
  label?: string;
  icon?: React.ReactNode;
} & React.ComponentProps<typeof Button>;

export default function AppButton(props: AppButtonPropsType): React.JSX.Element {
  const {style = "primary"} = props;

  const getVariant = (): AppButtonVariant => {
    if (style === "primary") {
      return "outlined";
    }

    return "text";
  };

  return (
    <Button id={props.id} variant={getVariant()} onClick={props.onClick} type={props.type} sx={props.sx}>
      {props.icon}
      {props.label}
    </Button>
  );
}
