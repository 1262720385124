import React from "react";
import {useNavigate} from "react-router";

import {Grid, Alert, Card, Button, Typography} from "@mui/material";

import errors from "../assets/error/errors.png";

interface ErrorBoundaryOverlayProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorBoundaryOverlay: React.FC<ErrorBoundaryOverlayProps> = ({error}) => {
  const navigate = useNavigate();

  const relaodCurrentPage = () => {
    navigate(0);
  };

  return (
    <Card className="search-card">
      <Grid container justifyContent="center" sx={{backgroundColor: "transparent"}}>
        <Grid xs={12} item>
          <Typography variant="h4" gutterBottom style={{textAlign: "center", marginTop: "50px"}}>
            Oups ! Un problème est survenu !
          </Typography>
        </Grid>
        <Grid xs={12} md={8} item style={{marginTop: "30px"}}>
          <Typography variant="body1">
            Pendant que nous résolvons le problème, vous pouvez :
            <ul>
              <li>Essayer d'actualiser la page à l'aide du bouton.</li>
              <li>Revenir à la page d'accueil et explorer d'autres parties de notre site.</li>
              <li>Contacter notre service client pour obtenir de l'aide.</li>
            </ul>
          </Typography>
        </Grid>
        <Grid xs={12} md={4} item style={{marginTop: "30px"}}>
          <Typography variant="body1">Erreur technique à transmettre</Typography>
          <Alert variant="outlined" style={{marginTop: "10px"}} severity="error">
            {error.message}
          </Alert>
        </Grid>
        <Grid xs={12} item style={{textAlign: "center"}}>
          {/* <div className="four_zero_four_bg"></div> */}
          <Button
            style={{marginBottom: "40px", marginTop: "50px"}}
            variant="outlined"
            color="primary"
            onClick={relaodCurrentPage}>
            Actualiser la page
          </Button>
        </Grid>
        <Grid xs={12} item style={{textAlign: "center"}}>
          <img alt="error" width="500" src={errors} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ErrorBoundaryOverlay;
