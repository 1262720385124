import {useState} from "react";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";

import WarningIcon from "@mui/icons-material/Warning";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";

import ConfirmDialogService from "./ConfirmDialogService";
import {AlertColor} from "@mui/material";

import {Divider} from "antd";

let executeCallback = () => {};

function ConfirmDialog() {
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [width, setWidth] = useState<string>("80%");
  const [askMessage, setAskMessage] = useState<string>("");
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  ConfirmDialogService.subject().subscribe({
    next: (confirmDialogMessage) => {
      switch (confirmDialogMessage.size) {
        case "xs":
          setWidth("50%");
          break;
        case "md":
          setWidth("80%");
          break;
        case "lg":
          setWidth("100%");
          break;
        default:
          setWidth("80%");
      }
      setAskMessage(confirmDialogMessage.askMessage);
      setTitle(confirmDialogMessage.title);
      setConfirmMessage(confirmDialogMessage.confirmMessage);
      executeCallback = confirmDialogMessage.callbackConfirmation;
      setSeverity(confirmDialogMessage.severity);
      setWidth(confirmDialogMessage.size);
      setOpen(true);
    },
  });

  ConfirmDialogService.getCloseSubject().subscribe({
    next: () => {
      setOpen(false);
    },
  });

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    executeCallback();
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };

  return (
    <Dialog sx={{"& .MuiDialog-paper": {width: {width}, maxHeight: 435}}} open={open}>
      <DialogTitle style={{padding: 0}}>
        <Toolbar>
          {severity === "info" && <InfoIcon color={severity} />}
          {severity === "warning" && <WarningIcon color={severity} />}
          {severity === "error" && <ErrorOutlineIcon color={severity} />}
          <Typography variant="h6" component="div" sx={{flexGrow: 1, marginTop: 0, marginLeft: 2}}>
            {title}
          </Typography>
        </Toolbar>
      </DialogTitle>
      <Divider style={{margin: 0}}></Divider>
      <DialogContent>
        <p dangerouslySetInnerHTML={{__html: askMessage}}></p>
        <p dangerouslySetInnerHTML={{__html: confirmMessage}}></p>
      </DialogContent>
      <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
        <Button variant="outlined" color="inherit" autoFocus onClick={handleCancel}>
          Annuler
        </Button>
        <Button id="confirmButtonForm" variant="outlined" onClick={handleOk}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
