import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {StatusCodes} from "http-status-codes";

import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import {Grid} from "@mui/material";
import {Card} from "@mui/material";
import {Button} from "@mui/material";
import {IconButton} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";

import SearchTextField from "../../../component/form/SearchTextField";
import CustomNoRowsOverlay from "../../../component/CustomNoRowsOverlay";

import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MailLockIcon from "@mui/icons-material/MailLock";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import ToastService from "../../../service/ToastService";

import DialogClient from "./DialogClient";
import ConfirmDialogService from "../../../service/ConfirmDialogService";

import {ClientPlant} from "../../../interfaces/client/ClientPlant";
import {ClientSearch} from "../../../interfaces/client/ClientSearch";

import apiV2 from "../../../service/api/ApiV2";
import {getClientAppUrl} from "../../../service/ClientAppService";

interface ActionMenuProps {
  onReset: () => void;
  onUpdate: () => void;
  onRemove: () => void;
}

const ActionMenu: React.FC<ActionMenuProps> = ({onReset, onUpdate, onRemove}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} id="subActionMenu">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: "270px",
            },
          },
        }}>
        <MenuItem
          id="sendEmailResetPasswordClientButtonDialog"
          onClick={() => {
            onReset();
            handleClose();
          }}>
          <ListItemIcon>
            <MailLockIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Réinitialiser le mot de passe</ListItemText>
        </MenuItem>
        <MenuItem
          id="updateClientButtonDialog"
          onClick={() => {
            onUpdate();
            handleClose();
          }}>
          <ListItemIcon>
            <EditIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Modifier le client</ListItemText>
        </MenuItem>
        <MenuItem
          id="deleteClientButtonDialog"
          onClick={() => {
            onRemove();
            handleClose();
          }}>
          <ListItemIcon>
            <DeleteOutlineIcon color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Supprimer le client</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default function ListsClientSearchView() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  interface Column {
    id: "client" | "email" | "phone" | "actions";
    label: string;
    description: string;
    minWidth?: number;
    align?: "center";
    format?: (value: number) => string;
  }

  const [rows, setRow] = useState<Array<ClientSearch>>([]);
  const [searchClient, setSearchClient] = useState<string>("");
  const [clientSearchUpdated, setClientSearchUpdated] = useState<ClientSearch | null>();
  const [titleDialog, setTitleDialog] = useState<string>("");
  const [actionLabelDialog, setActionLabelDialog] = useState<string>("Créer");
  const [contentTextDialog, setContentTextDialog] = useState<string>("");
  const [contentTextErrorDialog, setContentTextErrorDialog] = useState<string>("");
  const [clientPlant, setClientPlant] = useState<Array<ClientPlant>>([]);
  const [open, setOpen] = useState(false);
  const [openDeleteError, setOpenDeleteError] = useState(false);

  const onChange = (searchedVal: string) => {
    apiV2.searchClients(searchedVal).then((data) => {
      if (data.length > 0) {
        setRow(data);
      } else {
        ToastService.warning("Aucun client trouvé pour votre recherche");
      }
    });
    setSearchClient(searchedVal);
  };

  const handleCloseDialogClient = () => {
    setOpen(false);
    setClientSearchUpdated(null);
  };

  const handleCloseDialogDeleteError = () => {
    setOpenDeleteError(false);
  };

  const handleAddNewClient = () => {
    setTitleDialog("Créer un nouveau client");
    setContentTextDialog(
      "Déclarer un nouveau compte client. Cette opération va créer un utilisateur qui pourra accèder à l'espace client pour qu'il consulte les données des installations qui lui seront associées.",
    );
    setActionLabelDialog("Créer");
    setOpen(true);
  };

  const handleUpdateClient = (client: ClientSearch) => {
    setTitleDialog("Modifier un client");
    setContentTextDialog(
      "Modifier le compte client " + client.firstName + " " + client.lastName + " ou completez ses données manquantes.",
    );
    setActionLabelDialog("Modifier");
    setOpen(true);
    setClientSearchUpdated(client);
  };

  const handleUpdateRowClient = (client: ClientSearch) => {
    setRow(
      rows.map((row) => {
        if (row.id === client.id) return client;
        return row;
      }),
    );
  };

  const handleSendEmailResetPasswordClient = (client: ClientSearch) => {
    ConfirmDialogService.info(
      "md",
      "Réinitialiser le mot de passe",
      "Un email sera envoyé au client <strong>" +
        client.firstName +
        " " +
        client.lastName +
        "</strong> pour l'inviter à définir un nouveau mot de passe. Le lien de réinitialisation inclus dans cet email ne restera actif que pour 24h.</br>     Êtes-vous sûr ?",
      "Le mail sera envoyé à <strong>" + client.email + "</strong>",
      () => {
        apiV2.sendEmailResetPasswordClient(client.id).then((response) => {
          if (response.ok) {
            ToastService.success(
              "Le client " +
                client.firstName +
                " " +
                client.lastName +
                " va recevoir un lien de réinitialisation par mail",
            );
          } else {
            ToastService.error("Erreur durant la demande de réinitialisation du mot de passe du client");
          }
        });
      },
    );
  };

  const handleRemoveClient = (client: ClientSearch) => {
    ConfirmDialogService.warning(
      "md",
      "Supprimer le client",
      "Etes-vous sur de vouloir supprimer le client <strong>" +
        client.firstName +
        " " +
        client.lastName +
        "</strong> ?",
      "<strong>Cette action est irréversible.</strong>",
      () => {
        apiV2.deleteClient(client.id).then((response) => {
          if (response.ok) {
            ToastService.success("Le client " + client.firstName + " " + client.lastName + " a été supprimé");
            setRow(rows.filter((row) => row.id !== client.id));
          } else if (response.status === StatusCodes.BAD_REQUEST) {
            response.json().then((resp) => {
              const errorsServerSide = JSON.parse(resp.detail);
              setOpenDeleteError(true);
              setClientPlant(errorsServerSide.plants);
              setContentTextErrorDialog(errorsServerSide.message);
            });
          } else {
            ToastService.error("Erreur durant la suppression de le client");
          }
        });
      },
    );
  };

  const getClientApp = (client: ClientSearch): string => {
    if (client.plantIds) {
      const isLumioo = client.platformType === "lumioo";
      return getClientAppUrl(isLumioo, client.plantIds[0]);
    } else {
      return "";
    }
  };

  const openInstallationView = (plantName: string): void => {
    navigate("/fleet/plant?plant_name=" + plantName);
  };

  const columns: Column[] = [
    {
      id: "client",
      label: "Client",
      description: "Information du client",
    },
    {
      id: "email",
      label: "Email",
      description: "Email associé au client",
    },
    {
      id: "phone",
      label: "Téléphone",
      description: "Numéro de téléphone associé au client",
    },
    {
      id: "actions",
      label: "Actions",
      description: "Action disponible",
    },
  ];

  return (
    <Grid container sx={{backgroundColor: "transparent"}}>
      <Card className="search-card">
        <Grid container justifyContent="space-between" sx={{backgroundColor: "transparent"}}>
          <Grid xs={12} sm={12} md={6} lg={9} item>
            <SearchTextField
              value={searchClient}
              notifyOnClear={false}
              width={"285px"}
              placeholder="Par nom, prénom, email..."
              onChange={onChange}
              helper="La recherche doit contenir au moins 3 caractères."></SearchTextField>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={3}
            item
            style={{
              paddingTop: isMobile ? 0 : 30,
              paddingBottom: isMobile ? 20 : 0,
            }}>
            <Button
              id="createClientButtonDialog"
              sx={{width: "100%"}}
              variant="outlined"
              onClick={handleAddNewClient}
              startIcon={<PersonAddIcon />}>
              Créer un nouveau client
            </Button>
          </Grid>
        </Grid>
      </Card>

      <Card className="custom-card">
        <Grid container sx={{backgroundColor: "transparent", pl: 0, pr: 0}}>
          <Grid item xs={12} style={{padding: "10px"}}>
            <Paper sx={{width: "100%", overflow: "hidden", boxShadow: "none"}}>
              {rows.length === 0 && (
                <CustomNoRowsOverlay noRowText="Effectuer une recherche pour trouver un client" loading={null} />
              )}
              {rows.length !== 0 && (
                <TableContainer sx={{boxShadow: "none"}}>
                  <Table stickyHeader aria-label="sticky table">
                    {rows.length >= 30 && (
                      <caption>
                        Seuls les 30 premiers résultats sont affichés, veuillez préciser votre recherche.
                      </caption>
                    )}
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            align="center"
                            style={{
                              minWidth: column.minWidth,
                              fontWeight: "bold",
                            }}>
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((client) => (
                        <TableRow
                          key={client.id}
                          sx={{
                            "&:last-child td, &:last-child th": {border: 0},
                          }}>
                          <TableCell style={{padding: 0}} align="center">
                            <List>
                              <ListItem>
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography sx={{display: "inline"}} component="span" color="text.primary">
                                        {client.firstName + " " + client.lastName.toUpperCase() + " "}
                                      </Typography>
                                      {client.aliasCompany && (
                                        <Typography
                                          sx={{display: "inline"}}
                                          component="span"
                                          variant="body2"
                                          color={theme.palette.primary.main}>
                                          ({client.aliasCompany})
                                        </Typography>
                                      )}
                                    </React.Fragment>
                                  }
                                  secondary={client.address}
                                />
                              </ListItem>
                            </List>
                          </TableCell>
                          <TableCell style={{padding: 0}} align="center">
                            {client.email}
                          </TableCell>
                          <TableCell align="center">{client.phone}</TableCell>
                          <TableCell align="center" style={{width: "120px"}}>
                            <Grid container spacing={3} sx={{backgroundColor: "transparent"}}>
                              <Grid item xs={6}>
                                <Tooltip
                                  title={
                                    client.plantIds?.length === 0
                                      ? "Ce client ne possède aucune installation"
                                      : "Ouvrir l'application client"
                                  }>
                                  <span>
                                    <IconButton
                                      disabled={client.plantIds?.length === 0}
                                      id="openClientApp"
                                      aria-label="open app client"
                                      edge="start"
                                      href={getClientApp(client)}
                                      target="_blank"
                                      sx={{
                                        marginLeft: "10px",
                                      }}>
                                      <PhonelinkIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={6}>
                                <Tooltip title="Plus d'options" placement="top">
                                  <div>
                                    <ActionMenu
                                      onReset={() => handleSendEmailResetPasswordClient(client)}
                                      onUpdate={() => handleUpdateClient(client)}
                                      onRemove={() => handleRemoveClient(client)}
                                    />
                                  </div>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Card>

      <DialogClient
        id={1}
        title={titleDialog}
        contentText={contentTextDialog}
        actionLabel={actionLabelDialog}
        clientSearchUpdated={clientSearchUpdated !== undefined ? clientSearchUpdated : null}
        open={open}
        handleUpdateRowClient={handleUpdateRowClient}
        handleClose={handleCloseDialogClient}
      />

      <Dialog open={openDeleteError} onClose={handleCloseDialogDeleteError} style={{borderRadius: 22}}>
        <DialogTitle style={{padding: 0}}>
          <Toolbar>
            <WarningIcon color="warning" />
            <Typography variant="h6" component="div" sx={{flexGrow: 1, marginTop: 0, marginLeft: 2}}>
              Impossible de supprimer le client
            </Typography>
            <IconButton
              color="secondary"
              style={{background: "rgba(53, 186, 7, 0.12)"}}
              aria-label="close dialog"
              edge="start"
              onClick={handleCloseDialogDeleteError}>
              <CloseIcon style={{color: "black"}} />
            </IconButton>
          </Toolbar>
        </DialogTitle>
        <Divider style={{margin: 0}}></Divider>
        <DialogContent>
          <DialogContentText style={{marginBottom: 20}}>{contentTextErrorDialog}</DialogContentText>
          <List sx={{width: "100%", minWidth: 450}}>
            {clientPlant.map((plant, index) => {
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Button
                      onClick={() => openInstallationView(plant.name)}
                      style={{marginRight: "-12px"}}
                      variant="outlined">
                      details
                    </Button>
                  }>
                  <ListItemIcon>
                    <SolarPowerIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{width: "100%", maxWidth: 250}}
                    id="switch-list-label-wifi"
                    primary={plant.name}
                    secondary={plant.plantAddress}
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
          <Button
            id="closeButtonClientPlantError"
            variant="outlined"
            color="inherit"
            onClick={handleCloseDialogDeleteError}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
