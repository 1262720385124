import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import dayjs, {type Dayjs} from "dayjs";

import Grid from "@mui/material/Grid";

import PlantStatusEnum from "../../../../enums/PlantStatusEnum";
import {AppDispatch, RootState} from "../../../../store/store";
import {fetchAgencies} from "../../../../store/form/agencies.store";
import ControlledSelect from "../../../../component/form/ControlledSelect";
import {PlantFilters} from "../../../../interfaces/control/PlantFilters";
import AppDatepicker from "../../../../component/AppDatepicker";
import SearchTextField from "../../../../component/form/SearchTextField";
import {
  setSearchFilter,
  setBeginFilter,
  setAgencyFilter,
  setEndFilter,
  setStatusFilter,
} from "../../../../store/control/ControlFiltersSlice";

type PlantsToControlFiltersProps = {
  onFilterChange: (filters: PlantFilters) => void;
};

const PlantsToControlFilters = ({onFilterChange}: PlantsToControlFiltersProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamStatus = searchParams.get("status") as PlantStatusEnum;
  const searchParamAgency = searchParams.get("agency");
  const searchParamBegin = searchParams.get("begin");
  const searchParamEnd = searchParams.get("end");
  const searchParamSearch = searchParams.get("search");
  const searchFilters = useSelector((state: RootState) => state.controlFilters);

  const {control, watch, setValue} = useForm<PlantFilters>({
    defaultValues: {
      status:
        searchParamStatus !== null
          ? searchParamStatus
          : searchFilters.status !== null
            ? searchFilters.status
            : PlantStatusEnum.ALL,
      agency: searchParamAgency !== null ? parseInt(searchParamAgency) : searchFilters.agency,
      begin:
        searchParamBegin !== null
          ? searchParamBegin
          : searchFilters.begin !== "" && searchFilters.begin !== null
            ? searchFilters.begin
            : dayjs().subtract(30, "day").format("YYYY-MM-DD"),
      end:
        searchParamEnd !== null
          ? searchParamEnd
          : searchFilters.end !== "" && searchFilters.end !== null
            ? searchFilters.end
            : dayjs().subtract(6, "day").format("YYYY-MM-DD"),
    },
  });
  const dispatch = useDispatch<AppDispatch>();
  const agenciesList = useSelector((state: RootState) => state.agencies.data);

  useEffect(() => {
    if (agenciesList.length === 0) {
      dispatch(fetchAgencies());
    }
  }, []);

  const [search, setSearch] = useState<string>(
    searchParamSearch !== null ? searchParamSearch : searchFilters.search !== "" ? searchFilters.search : "",
  );
  const status = watch("status");
  const agency = watch("agency");
  const begin = watch("begin");
  const end = watch("end");

  useEffect(() => {
    onFilterChange({
      search: search,
      status: status,
      agency: agency,
      begin: begin,
      end: end,
    });
    setSearchParams(() => {
      const params = new URLSearchParams();
      if (search !== "") {
        params.append("search", search);
      }
      if (status !== PlantStatusEnum.ALL) {
        params.append("status", status);
      }
      if (agency !== -1) {
        params.append("agency", `${agency}`);
      }
      if (begin !== "" && begin !== null) {
        params.append("begin", `${begin}`);
      }
      if (end !== "" && end !== null) {
        params.append("end", `${end}`);
      }
      return params;
    });
    dispatch(setSearchFilter(search));
    dispatch(setStatusFilter(status));
    dispatch(setAgencyFilter(agency));
    dispatch(setBeginFilter(begin));
    dispatch(setEndFilter(end));
  }, [search, status, agency, begin, end]);

  const getLastCompletedWeek = (): [Dayjs, Dayjs] => {
    const endOfLastWeek = dayjs().startOf("week").subtract(1, "day");
    const startOfLastWeek = endOfLastWeek.startOf("week");
    return [startOfLastWeek, endOfLastWeek];
  };

  const onDatesChange = (dates: [Dayjs | null, Dayjs | null]) => {
    if (dates[0] !== null) {
      setValue("begin", dates[0].format("YYYY-MM-DD"));
    } else {
      setValue("begin", null);
    }
    if (dates[1]) {
      setValue("end", dates[1].format("YYYY-MM-DD"));
    } else {
      setValue("end", null);
    }
  };

  return (
    <Grid
      xs={12}
      container
      direction={{xs: "column", md: "column", lg: "row"}}
      columnSpacing={{xs: 0, md: 2}}
      rowSpacing={{xs: 2, md: 0}}>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <SearchTextField
          value={search}
          notifyOnClear={true}
          onChange={(value) => setSearch(value)}
          width={"100%"}
          placeholder={"Par nom, addresse, numéro de série ou id..."}
          title={"Rechercher par nom, addresse, numéro de série ou id"}
          helper="La recherche doit contenir au moins 3 caractères."
          autoComplete={"off"}
        />
      </Grid>

      <Grid item xs={12} md={2} lg={2}>
        <ControlledSelect
          name={"status"}
          style={{marginTop: 15}}
          label={"Statut"}
          control={control}
          variant={"outlined"}
          fullWidth
          id={"status"}
          options={[
            {value: PlantStatusEnum.ALL, label: "Tous les statuts"},
            {value: PlantStatusEnum.TO_CONTROL, label: "A contrôler"},
            {
              value: PlantStatusEnum.CONTROL_IN_PROGRESS,
              label: "Contrôle en cours",
            },
            {value: PlantStatusEnum.COMPLIANT, label: "Conforme"},
            {value: PlantStatusEnum.NOT_COMPLIANT, label: "Non conforme"},
          ]}
        />
      </Grid>

      <Grid item xs={12} md={2} lg={2}>
        <ControlledSelect
          name={"agency"}
          style={{marginTop: 15}}
          label={"Agence"}
          id={"agency"}
          control={control}
          fullWidth
          variant={"outlined"}
          options={[
            {value: -1, label: "Toutes les agences"},
            ...agenciesList.map((agency) => ({
              value: agency.id,
              label: agency.name,
            })),
          ]}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: 15, marginBottom: 15}}>
        <AppDatepicker
          label={"Date de mise en route"}
          value={[begin !== null ? dayjs(begin) : null, end !== null ? dayjs(end) : null]}
          onChange={onDatesChange}
          allowEmpty={[true, true]}
          style={{backgroundColor: "inherit", height: 55}}
          placeholder={["Entre le...", "et le..."]}
          additionalRangePresets={[{label: "Semaine dernière", value: getLastCompletedWeek()}]}
          showArrows
        />
      </Grid>
    </Grid>
  );
};

export default PlantsToControlFilters;
