import {Box, Stack} from "@mui/material";
import {ReactNode} from "react";

interface Props {
  children: ReactNode;
}
export default function SizeaFormGroup(props: Props) {
  return (
    <Box
      sx={{
        borderStyle: "dashed",
        borderWidth: 1,
        borderColor: "lightgray",
        padding: 1,
      }}>
      <Stack direction="row" spacing={2} sx={{}}>
        <Box
          sx={{
            width: "100%",
          }}>
          {props.children}
        </Box>
      </Stack>
    </Box>
  );
}
