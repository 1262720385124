import {Box, Stack} from "@mui/material";

interface Props {
  logo: any;
  label: string;
}
export default function SizeaNoFormLine(props: Props) {
  return (
    <Box
      sx={{
        padding: 1,
      }}>
      <Stack direction="row" spacing={1} justifyContent={"center"}>
        <Box
          sx={{
            maxWidth: 50,
            padding: 1,
          }}>
          {props.logo}
        </Box>
        <Box
          sx={{
            lineHeight: 4,
          }}>
          {props.label}
        </Box>
      </Stack>
    </Box>
  );
}
