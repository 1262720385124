import {SimulationDto} from "./dto";
import {
  EssInput,
  OtherProductionSourceInput,
  PlantInputInterface,
  SimulationRequestInterface,
  TrackerTypeInterface,
  WaterPointInput,
} from "./simulationRequestDto";

const getPlantInputUrl = function (plantInput: PlantInputInterface | undefined): string {
  if (plantInput === undefined) {
    return "";
  }

  let href = "";

  if (plantInput.name) {
    href += `name=${plantInput.name}`;
  }
  if (plantInput.address) {
    href += `&address=${plantInput.address}`;
  }
  if (plantInput.latitude) {
    href += `&latitude=${plantInput.latitude}`;
  }
  if (plantInput.longitude) {
    href += `&longitude=${plantInput.longitude}`;
  }
  if (plantInput.consumptionFileTypeEnum) {
    href += `&consumptionFileTypeEnum=${plantInput.consumptionFileTypeEnum}`;
  }
  if (plantInput.consumptionFileUuid) {
    href += `&consumptionFileUuid=${plantInput.consumptionFileUuid}`;
  }

  if (plantInput.startDate) {
    href += `&startDate=${plantInput.startDate}`;
  }
  if (plantInput.endDate) {
    href += `&startDate=${plantInput.endDate}`;
  }

  return href;
};

const getCustomUrl = function (simulationRequest: SimulationRequestInterface): string {
  let href = "";

  if (simulationRequest.isCustom) {
    href += `&isCustom=1`;
    href += `&template=custom`;
  }
  if (simulationRequest.template && simulationRequest.template !== "custom") {
    href += `&template=${simulationRequest.template}`;
  }
  if (simulationRequest.customTemplate) {
    href += `&customTemplate=${simulationRequest.customTemplate}`;
  }

  return href;
};

const getTrackerInputsUrl = function (trackerInputs: TrackerTypeInterface[] | undefined): string {
  if (trackerInputs === undefined) {
    return "";
  }
  if (trackerInputs.length > 0) {
    return "";
  }

  // le format attendu
  // trackers=[[3,"01111111-9ac7-6509-014b-8b14ec3d7614",22000],[4,"01111111-9ac7-6509-014b-8b14ec3d7613",21500]]
  return (
    "&trackers=[" +
    trackerInputs
      .map(
        (item) =>
          `[
            ${item.trackerQuantity},
            "${item.trackerTypeId}",
            ${item.customRatedPower}
          ]`,
      )
      .join(",") +
    "]"
  );
};

const getOtherProductionSourceInputsUrl = function (
  otherProductionSourceInputs: OtherProductionSourceInput[] | undefined,
): string {
  if (otherProductionSourceInputs === undefined) {
    return "";
  }
  if (otherProductionSourceInputs.length > 0) {
    return "";
  }

  return (
    "&otherProductionSources=[" +
    otherProductionSourceInputs
      .map(
        (item) =>
          `[
            "${item.productionFileUuid}",
            "${item.label}"
          ]`,
      )
      .join(",") +
    "]"
  );
};

const getWaterPointInputsUrl = function (waterPointInputs: WaterPointInput[] | undefined): string {
  if (waterPointInputs === undefined) {
    return "";
  }
  if (waterPointInputs.length > 0) {
    return "";
  }

  return (
    "&hotWaterRequirements=[" +
    waterPointInputs
      .map(
        (item) =>
          `[
            "${item.hotWaterTankType}",
            "${item.hotWaterRequirementFileUuid}",
            ${item.volumeCuve},
            ${item.targetTemperature},
            ${item.immersionHeaterQuantity},
            "${item.immersionHeaterTypeId}"
          ]`,
      )
      .join(",") +
    "]"
  );
};

const getEssInputsUrl = function (essInputs: EssInput[] | undefined): string {
  if (essInputs === undefined) {
    return "";
  }
  if (essInputs.length > 0) {
    return "";
  }

  return (
    "&essList=[" +
    essInputs
      .map(
        (item) =>
          `[
            ${item.rescuedReserve},
            "${item.essTypeId}",
            "${item.converterTypeId}",
            ${item.nbConverter},
            "${item.batteryTypeId}",
            ${item.nbBattery}
          ]`,
      )
      .join(",") +
    "]"
  );
};

export default function ReplayUrlBuilder(simulation: SimulationDto): string {
  const simulationRequestString = simulation.simulationRequest;

  let simulationRequest = null;
  if (simulationRequestString) {
    simulationRequest = JSON.parse(simulationRequestString) as SimulationRequestInterface;
  }

  let href = `/sizea/form?`;
  if (simulationRequest === null) {
    return href;
  }

  href += getPlantInputUrl(simulationRequest.plantInput);
  href += getCustomUrl(simulationRequest);

  const productionInput = simulationRequest.productionInput;
  if (productionInput.isRestricted) {
    href += `&isRestricted=1`;
  }

  href += getTrackerInputsUrl(productionInput.trackerInputs);
  href += getOtherProductionSourceInputsUrl(simulationRequest.otherProductionSourceInputs);
  href += getWaterPointInputsUrl(simulationRequest.waterPointInputs);
  href += getEssInputsUrl(simulationRequest.essInputs);

  return href;
}
