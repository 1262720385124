import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import apiSizea from "../../service/api/ApiSizea";
import {BatteryType} from "../../interfaces/ess/BatteryType";

interface batteryTypesState {
  data: BatteryType[];
  loading: boolean;
  error: string | null;
}

const initialState: batteryTypesState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchBatteryTypes = createAsyncThunk("batteryTypes/fetchBatteryTypes", async () => {
  return apiSizea.batteryTypes();
});

const batteryTypesSlice = createSlice({
  name: "batteryTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatteryTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBatteryTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchBatteryTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default batteryTypesSlice.reducer;
