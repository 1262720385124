import apiSizea from "../../../../service/api/ApiSizea";
import {useEffect, useState} from "react";
import {MenuItem, Select} from "@mui/material";
import {UserDto} from "../dto";

interface Props {
  onChange: CallableFunction;
}
export function SelectUser(props: Props) {
  const [users, setUsers] = useState<UserDto[]>([]);

  useEffect(() => {
    apiSizea.users().then((data) => {
      const blankUser: UserDto = {
        id: "all",
        firstName: "Tous les utilisateurs",
        lastName: "",
      };
      setUsers([blankUser, ...data]);
    });
  }, []);

  const onChange = function (userId: string): void {
    let updatedUserId: string | null = userId;
    if (userId === "all") {
      updatedUserId = null;
    }
    props.onChange(updatedUserId);
  };

  return (
    <Select onChange={(value) => onChange(value.target.value)} style={{width: "100%"}} defaultValue={"all"}>
      {users.map((user: UserDto) => (
        <MenuItem key={user.id} value={user.id}>
          {user.firstName} {user.lastName}
        </MenuItem>
      ))}
    </Select>
  );
}
