import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import ToastService from "./ToastService";

type CopyBoardTextProps = {
  text: string | undefined;
};

const ClipBoardButton = (props: CopyBoardTextProps) => {
  const handleCopy = () => {
    if (props.text) {
      navigator.clipboard.writeText(props.text);
      ToastService.success(props.text + " a été copié dans le presse-papiers");
    }
  };

  return (
    <Tooltip title="Copier" style={{marginTop: "-5px"}}>
      <IconButton onClick={handleCopy} data-cy="cypressTrackerCardCopyButton" aria-label="copy" size="small">
        <ContentCopyIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export default ClipBoardButton;
