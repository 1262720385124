import {ChartData, ChartDataset, ChartOptions} from "chart.js";
import {Line} from "react-chartjs-2";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";
import {mergeObjectsRecursive} from "../../../../utils/utils";
import {Plant} from "../../../../interfaces/Plant";
import {Dayjs} from "dayjs";
import {useTheme} from "@mui/material";
import {useEffect, useState, useRef} from "react";
import {formatXLabels, generateDates} from "../DateService";
import apiV2 from "../../../../service/api/ApiV2";
import {forkJoin} from "rxjs";
import {CrosshairPlugin} from "../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair";
import {
  borderDashBasedOnDisconnectionAlarms,
  createAlarmTimeserie,
  createYLabels,
  eventLevelColor,
  getCurrentAlarmIndexesFromReference,
  mappingAlarmsToYvalues,
} from "../AlarmService";
import {Grid, Typography} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import GraphResizeService from "../../../../service/GraphResizeService";
import {Meter} from "../../../../interfaces/Meter";

type MeterEventChartProps = {
  plant: Plant;
  meter: Meter;
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

interface LegendAlarm {
  id: number;
  label: string;
  reference: string;
  color: string;
}

export function MeterEventChart(props: MeterEventChartProps) {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [width, setWidth] = useState(0);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    labels: [],
    datasets: [],
  });
  const [mappingAlarmYvalues, setMappingAlarmYvalues] = useState<{
    [key: string]: number;
  }>({});
  const [legendAlarms, setLegendAlarms] = useState<Array<LegendAlarm>>([]);

  useEffect(() => {
    GraphResizeService.checkResizeWidth(ref, width, setWidth);
  }, [ref, width, setWidth]);

  useEffect(() => {
    const expectedDates = generateDates(props.startDate, props.endDate);

    async function updateDatasets(datasets: ChartDataset<"line">[]): Promise<void> {
      let alarms = await apiV2.meterAlarms(props.startDate, props.endDate, props.meter.id);
      alarms = alarms.map((alarm) => {
        return {...alarm, reference: alarm.reference.replace("METER_", " ")};
      });
      const legendAlarms = alarms.map((alarm, index) => {
        const legend = {} as LegendAlarm;
        legend.id = index;
        legend.reference = alarm.reference;
        legend.label = alarm.label;
        legend.color = eventLevelColor(alarm.level, theme);
        return legend;
      });

      const alarmYValues = mappingAlarmsToYvalues(alarms);
      setMappingAlarmYvalues(alarmYValues);
      setLegendAlarms(
        legendAlarms.filter((alarm1, i, arr) => arr.findIndex((alarm2) => alarm2.label === alarm1.label) === i),
      );
      for (let i = 0; i < alarms.length; i++) {
        datasets.push({
          label: `${alarms[i].reference}`,
          data: createAlarmTimeserie(expectedDates, alarms[i], alarmYValues[alarms[i].reference]),
          borderColor: eventLevelColor(alarms[i].level, theme),
          backgroundColor: eventLevelColor(alarms[i].level, theme),
          borderWidth: 10,
          segment: {
            borderDash: (ctx) => {
              const disconnectionAlarmIndexes = getCurrentAlarmIndexesFromReference(alarms, "DISCONNECT");
              return borderDashBasedOnDisconnectionAlarms(ctx, expectedDates, alarms, disconnectionAlarmIndexes, i);
            },
          },
        });
      }
    }

    const datasets: ChartDataset<"line">[] = [];
    const waitForDatasets = forkJoin({
      datasets: updateDatasets(datasets),
    });
    waitForDatasets.subscribe({
      next: () => {
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: datasets,
        });
      },
      error: () => {
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: [],
        });
      },
    });
  }, [props, theme]);

  const defaultOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Ev compteur",
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (tooltipItem) => {
            if (chartData.datasets.length === 0) return "";
            return chartData.datasets[tooltipItem.datasetIndex].label;
          },
        },
      },
      annotation: {
        annotations: createYLabels(mappingAlarmYvalues, props.startDate.tz(props.plant.timezone), theme),
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
          autoSkip: false,
          callback: () => {
            return "";
          },
        },
        min: Math.min(...Object.values(mappingAlarmYvalues)) - 1, // min is set to display all labels
        max: Math.max(...Object.values(mappingAlarmYvalues)) + 1, // max is set to display all labels
      },
    },
  };

  const chartHeight = 90 + 20 * Object.keys(mappingAlarmYvalues).length;
  const options = mergeObjectsRecursive(defaultOptions, props.options);

  return (
    <div ref={ref} style={{width: "100%"}} className="scada-card-zoom">
      <ResizableBox
        transformScale={0.75}
        className="box"
        width={width}
        height={chartHeight}
        resizeHandles={["s"]}
        minConstraints={[100, chartHeight]}
        maxConstraints={[width, chartHeight * 4]}>
        {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
        <Line options={options} data={chartData} plugins={[CrosshairPlugin]} style={{marginLeft: "15px"}} />
      </ResizableBox>
      <div
        style={{
          marginLeft: isMobile ? "20px" : "80px",
          marginBottom: "5px",
          marginRight: "25px",
        }}>
        {legendAlarms.length > 0 && (
          <Grid container style={{marginTop: "5px", marginBottom: "5px"}}>
            {legendAlarms.map((element, index) => (
              <Grid item sm={12} md={6} sx={{paddingTop: 0, paddingBottom: 0}} key={index}>
                <Typography variant="subtitle2">
                  <span
                    style={{
                      height: "10px",
                      width: "10px",
                      backgroundColor: element.color,
                      borderRadius: "50%",
                      display: "inline-block",
                    }}></span>
                  <b style={{fontSize: "12px"}}>{element.reference}</b>: {element.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
}
