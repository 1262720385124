import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {Box, FormGroup, FormLabel, Grid, InputLabel, List, ListItem, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Simulation from "../model";
import AppButton from "../../../../component/atom/button/AppButton";
import AppEssIcon from "../../../../component/atom/icon/AppEssIcon";
import AppFormLine from "./SizeaFormLine";
import SizeaRemoveButton from "./SizeaRemoveButton";
import SizeaNoFormLine from "./SizeaNoFormLine";
import {SelectBatteryType} from "../component/SelectBatteryType";
import {SelectConverterType} from "../component/SelectConverterType";
import {SelectEssType} from "../component/SelectEssType";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";

type PropsType = {
  watchFieldArray: any[];
  control: Control<Simulation, any>;
  register: UseFormRegister<Simulation>;
  setValue: UseFormSetValue<Simulation>;
  watch: UseFormWatch<Simulation>;
  errors: FieldErrors<Simulation>;
  fields: FieldArrayWithId<Simulation, "essInputs", "id">[];
  append: UseFieldArrayAppend<Simulation, "essInputs">;
  remove: UseFieldArrayRemove;
};

export default function SizeaFormEss(props: PropsType) {
  const converterTypes = useSelector((state: RootState) => state.converterTypes);
  const batteryTypes = useSelector((state: RootState) => state.batteryTypes);

  const controlledFields = props.fields.map((fieldsHotWaterRequirements, index) => {
    return {
      ...fieldsHotWaterRequirements,
      ...props.watchFieldArray[index],
    };
  });

  const getTotalPower = (converterTypeId: string, nbConverter: number): string => {
    const type = converterTypes.data.find((converterType) => converterType.id === converterTypeId);
    if (type === undefined) return "";

    const totalPower = nbConverter * type.ratedPower;
    return new Intl.NumberFormat("fr-FR", {
      useGrouping: true,
    }).format(totalPower);
  };

  const getTotalCapacity = (batteryTypeId: string, nbBattery: number): string => {
    const type = batteryTypes.data.find((batteryType) => batteryType.id === batteryTypeId);
    if (type === undefined) return "";

    const totalPower = nbBattery * type.ratedCapacity;
    return new Intl.NumberFormat("fr-FR", {
      useGrouping: true,
    }).format(totalPower);
  };

  return (
    <>
      {controlledFields.length === 0 && (
        <SizeaNoFormLine logo={<AppEssIcon />} label="Aucun ESS pour cette étude"></SizeaNoFormLine>
      )}
      <List>
        {controlledFields.map((item, index) => {
          return (
            <AppFormLine key={item.id} logo={<AppEssIcon />}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={11}>
                  <List>
                    <ListItem>
                      <FormControl>
                        <Grid container alignItems="center" spacing={3} sx={{paddingTop: "5px"}}>
                          <Grid item xs={10}>
                            <FormGroup>
                              <TextField
                                type="number"
                                inputProps={{
                                  step: 100,
                                }}
                                required
                                error={!!props.errors.essInputs?.[index]?.rescuedReserve}
                                label="Réserve secourue (Wh)"
                                defaultValue={item.rescuedReserve}
                                {...props.register(`essInputs.${index}.rescuedReserve` as const, {
                                  required: true,
                                  valueAsNumber: true,
                                })}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <FormControl>
                        <Grid container alignItems="center" spacing={3}>
                          <Grid item xs={5}>
                            <FormControl>
                              <InputLabel required id="ess-type-label" defaultValue={item.essTypeId}>
                                Ref Type ESS
                              </InputLabel>
                              <SelectEssType
                                error={!!props.errors.essInputs?.[index]?.essTypeId}
                                sx={{minWidth: 200}}
                                control={props.control}
                                name={`essInputs.${index}.essTypeId`}
                                defaultValue={item.essTypeId}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <FormControl>
                        <FormLabel required id="storage-label">
                          Convertisseurs
                        </FormLabel>
                        <Grid container>
                          <FormControl>
                            <InputLabel required id="converter-type-label">
                              Ref convertisseur
                            </InputLabel>
                            <SelectConverterType
                              error={!!props.errors.essInputs?.[index]?.converterTypeId}
                              sx={{minWidth: 300, maxWidth: 300}}
                              control={props.control}
                              name={`essInputs.${index}.converterTypeId`}
                              defaultValue={item.converterTypeId}
                            />
                          </FormControl>
                          <FormGroup sx={{marginLeft: 1}}>
                            <TextField
                              type="number"
                              inputProps={{
                                min: 1,
                                max: 2,
                              }}
                              required
                              error={!!props.errors.essInputs?.[index]?.nbConverter}
                              label="Nbr convertisseurs"
                              defaultValue={item.nbConverter}
                              {...props.register(`essInputs.${index}.nbConverter` as const, {
                                required: true,
                                valueAsNumber: true,
                              })}
                            />
                          </FormGroup>
                          <Box>
                            Permet de convertir un maximum de {getTotalPower(item.converterTypeId, item.nbConverter)}W
                            de production solaire.
                          </Box>
                        </Grid>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <FormControl>
                        <FormLabel required id="storage-label">
                          Batteries
                        </FormLabel>
                        <Grid container>
                          <FormControl>
                            <InputLabel required id="battery-type-label">
                              Ref batterie
                            </InputLabel>
                            <SelectBatteryType
                              error={!!props.errors.essInputs?.[index]?.batteryTypeId}
                              sx={{minWidth: 300, maxWidth: 300}}
                              control={props.control}
                              name={`essInputs.${index}.batteryTypeId`}
                              defaultValue={item.batteryTypeId}
                            />
                          </FormControl>
                          <FormGroup sx={{marginLeft: 1}}>
                            <TextField
                              type="number"
                              inputProps={{
                                min: 1,
                                max: 10,
                              }}
                              required
                              error={!!props.errors.essInputs?.[index]?.nbBattery}
                              label="Nbr batteries"
                              defaultValue={item.nbBattery}
                              {...props.register(`essInputs.${index}.nbBattery` as const, {
                                required: true,
                                valueAsNumber: true,
                              })}
                            />
                          </FormGroup>
                        </Grid>
                        <Box>
                          Permet de stocker un maximum de {getTotalCapacity(item.batteryTypeId, item.nbBattery)} Wh de
                          capacité.
                        </Box>
                      </FormControl>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={1}>
                  <SizeaRemoveButton id={`removeEss-${index}`} onClick={() => props.remove(index)} />
                </Grid>
              </Grid>
            </AppFormLine>
          );
        })}
      </List>

      <Box
        sx={{
          textAlign: "center",
        }}>
        <AppButton
          style="primary"
          label="Ajouter un ESS"
          title="Ajouter un ESS"
          id={"addEssButton"}
          onClick={() => {
            props.append({
              essTypeId: "",
              nbBattery: 10,
              batteryTypeId: "",
              nbConverter: 1,
              converterTypeId: "",
              rescuedReserve: 0,
            });
          }}
        />
      </Box>
    </>
  );
}
