import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {Box, FormGroup, Grid, TextField} from "@mui/material";
import Simulation, {ConsumptionFileTypeRef, DateOptionRef} from "../model";
import AppButton from "../../../../component/atom/button/AppButton";
import AppFormLine from "./SizeaFormLine";
import SizeaRemoveButton from "./SizeaRemoveButton";
import SizeaNoFormLine from "./SizeaNoFormLine";
import sizeaApi from "../../../../service/api/ApiSizea";
import {HttpRequestHeader} from "antd/es/upload/interface";
import {v4 as uuidv4} from "uuid";
import {Upload} from "antd";
import {uploadOnChange} from "./UploadOnChange";
import AppOtherProductionIcon from "../../../../component/atom/icon/AppOtherProductionIcon";

type PropsType = {
  watchFieldArray: any[];
  control: Control<Simulation, any>;
  register: UseFormRegister<Simulation>;
  setValue: UseFormSetValue<Simulation>;
  watch: UseFormWatch<Simulation>;
  errors: FieldErrors<Simulation>;
  fields: FieldArrayWithId<Simulation, "productionInput.otherProductionSourceInputs", "id">[];
  append: UseFieldArrayAppend<Simulation, "productionInput.otherProductionSourceInputs">;
  remove: UseFieldArrayRemove;
};

export default function SizeaFormOtherProduction(props: PropsType) {
  const controlledFields = props.fields.map((fieldsRequirements, index) => {
    return {
      ...fieldsRequirements,
      ...props.watchFieldArray[index],
    };
  });

  return (
    <>
      {props.fields.length === 0 && (
        <SizeaNoFormLine
          logo={<AppOtherProductionIcon />}
          label="Aucun fichier de production pour cette étude"></SizeaNoFormLine>
      )}
      {controlledFields.map((item, index) => {
        return (
          <AppFormLine key={item.id} logo={<AppOtherProductionIcon />}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={11}>
                <FormGroup>
                  <TextField
                    required
                    error={!!props.errors.productionInput?.otherProductionSourceInputs?.[index]?.name}
                    label="Libellé"
                    sx={{
                      maxWidth: 400,
                    }}
                    {...props.register(`productionInput.otherProductionSourceInputs.${index}.name`, {required: true})}
                  />
                </FormGroup>
                <Upload
                  name="file"
                  accept=".xlsx"
                  action={sizeaApi.getUploadUrl()}
                  headers={sizeaApi.getUploadHeaders() as unknown as HttpRequestHeader}
                  onChange={uploadOnChange}
                  data={{
                    identifier: `production-${props.watch(`productionInput.otherProductionSourceInputs.${index}.productionFileUuid`)}`,
                    timezone: props.watch("plantInput.timezone"),
                    dateOptionEnum:
                      props.watch(`plantInput.consumptionFileTypeEnum`) === ConsumptionFileTypeRef.ENEDIS
                        ? DateOptionRef.MANUAL
                        : DateOptionRef.AUTO,
                    startDate: props.watch("plantInput.startDate"),
                    endDate: props.watch("plantInput.endDate"),
                  }}
                  beforeUpload={() => {
                    props.setValue(`productionInput.otherProductionSourceInputs.${index}.productionFileUuid`, uuidv4());
                  }}
                  listType="picture-card"
                  showUploadList={true}
                  maxCount={1}
                  style={{fontFamily: "Montserrat"}}
                  defaultFileList={
                    props.watch(`productionInput.otherProductionSourceInputs.${index}.productionFileUuid`)
                      ? [
                          {
                            uid:
                              props.watch(`productionInput.otherProductionSourceInputs.${index}.productionFileUuid`) ||
                              "",
                            name: "Fichier de production",
                          },
                        ]
                      : []
                  }>
                  Déposez vos fichiers ici
                </Upload>
              </Grid>
              <Grid item xs={1}>
                <SizeaRemoveButton id={`removeOtherProduction-${index}`} onClick={() => props.remove(index)} />
              </Grid>
            </Grid>
          </AppFormLine>
        );
      })}
      <Box
        sx={{
          textAlign: "center",
        }}>
        <AppButton
          id={"addOtherProduction"}
          title="Ajouter un fichier de production"
          onClick={() => {
            props.append({
              productionFileUuid: "",
              name: "",
            });
          }}
          style="primary"
          label="Ajouter un fichier de production"
        />
      </Box>
    </>
  );
}
