import {useEffect, useState} from "react";

import apiV2 from "../../../service/api/ApiV2";
import ToastService from "../../../service/ToastService";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import configuratorLogo from "../../../assets/software/configurator/logo.png";
import {ConfigurationCard} from "../../../interfaces/documentation/ConfigurationCard";
import DownloadButton from "../../../component/DownloadButton";

type ConfiguratorCardProps = {
  configuration: ConfigurationCard;
};

export default function ConfiguratorCard(props: ConfiguratorCardProps) {
  const [compatibleOS, setCompatibleOS] = useState<boolean>(false);
  const [typeOS, setTypeOS] = useState<string>("");

  useEffect(() => {
    if (window.navigator.userAgent.indexOf("Windows") !== -1) {
      setTypeOS("windows");
      setCompatibleOS(true);
    } else if (window.navigator.userAgent.indexOf("Linux") !== -1) {
      setTypeOS("linux");
      setCompatibleOS(true);
    } else {
      setCompatibleOS(false);
      ToastService.error(
        "Impossible de télécharger le configurateur pour un système d'exploitation différent de Windows ou Linux",
      );
    }
  }, []);

  return (
    <div style={{justifyContent: "center", display: "flex", width: "100%"}}>
      <Card
        style={{
          borderRadius: "8px",
          boxShadow: "none",
          height: "320px",
          padding: "10px",
          overflow: "overlay",
        }}
        sx={{display: "flex"}}>
        {!compatibleOS ? (
          <Typography style={{textAlign: "center", marginTop: "120px"}}>
            Le configurateur n'est pas compatible avec votre système d'exploitation
          </Typography>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{marginLeft: "10px"}} component="div" variant="h5">
                {props.configuration.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={3} style={{padding: "15px"}} sx={{backgroundColor: "transparent"}}>
              <CardMedia
                component="img"
                sx={{display: {xs: "none", sm: "block"}}}
                image={configuratorLogo}
                alt="Image accueil configurateur"
              />
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={{marginTop: "-15px"}}>
              <CardContent>
                <Typography variant="subtitle2" color="text.secondary" component="div">
                  <b>Description: </b> {props.configuration.description}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" component="div">
                  <b>Version:</b> Latest
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" component="div">
                  <b>Taille:</b> 250 Mo
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" component="div">
                  <b>Type de fichier:</b> (.exe)
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography
                  style={{marginLeft: "10px", marginTop: "-15px"}}
                  variant="subtitle2"
                  color="text.secondary"
                  component="div">
                  <b>Tag </b>
                  {props.configuration.tags.map((tag) => (
                    <Chip key={tag} style={{margin: "5px"}} label={tag}></Chip>
                  ))}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <DownloadButton
                icon={false}
                url={apiV2.downloadUrlConfigurator(typeOS)}
                filename={typeOS === "linux" ? "configurateur.AppImage" : "configurateur.exe"}></DownloadButton>
            </Grid>
          </Grid>
        )}
      </Card>
    </div>
  );
}
