import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/store";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";

import {DialogProps, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from "@mui/icons-material/Close";
import TransitionSlideUp from "../../../../../service/transition/TransitionSlideUp";

import trackerOkwind from "../../../../../assets/tracker/okwind.png";
import trackerTREA29000R from "../../../../../assets/tracker/TREA_29000R.png";
import trackerTREA40000 from "../../../../../assets/tracker/TREA_40000.png";
import trackerLumioo from "../../../../../assets/tracker/lumioo.png";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

import {TrackerActions} from "./TrackerActions";
import {TrackerGeneral} from "./TrackerGeneral";
import {TrackerEquipment} from "./TrackerEquipment";
import {TrackerSetting} from "./TrackerSetting";

import {Tracker} from "../../../../../interfaces/Tracker";

type DialogClientProps = {
  id: number;
  index: number;
  open: boolean;
  handleClose: () => void;
};

export default function TrackerDialog(props: DialogClientProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [maxWidth] = useState<DialogProps["maxWidth"]>("lg");
  const trackers = useSelector((state: RootState) => state.trackers);

  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    props.handleClose();
  };

  const getTrackerSrc = (tracker: Tracker) => {
    if (tracker.platform === "lumioo") {
      return trackerLumioo;
    }
    switch (tracker.trackerTypeLabel) {
      case "TREA29000-R":
        return trackerTREA29000R;
      case "TREA40000":
        return trackerTREA40000;
      default:
        return trackerOkwind;
    }
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullScreen={isMobile}
      open={props.open}
      onClose={handleClose}
      style={{borderRadius: 22}}
      TransitionComponent={TransitionSlideUp}>
      <DialogTitle style={{padding: 0}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            Tracker n°{trackers.data[props.index].nTrk} - Numero de série: {trackers.data[props.index].serialNumber}
          </Typography>
          <IconButton
            color="secondary"
            style={{background: "rgba(53, 186, 7, 0.12)"}}
            aria-label="close dialog"
            edge="start"
            onClick={props.handleClose}>
            <CloseIcon
              style={{
                color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
              }}
            />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider style={{margin: 0}}></Divider>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={5}>
            <img
              alt="tracker"
              style={{
                width: trackers.data[props.index].platform === "lumioo" ? "90%" : "100%",
              }}
              src={getTrackerSrc(trackers.data[props.index])}
            />
          </Grid>
          <Grid item xs={12} md={7} style={{height: "450px", overflow: "hidden", padding: "0px"}}>
            <TabContext value={value}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs tracker">
                <Tab label="Informations" value="1" />
                <Tab label="Équipements" value="2" />
                <Tab label="Paramètres" value="3" />
                <Tab label="Actions" value="4" />
              </Tabs>
              <TabPanel value="1" style={{padding: "10px"}}>
                <TrackerGeneral index={props.index}></TrackerGeneral>
              </TabPanel>
              <TabPanel value="2" style={{padding: "10px"}}>
                <TrackerEquipment index={props.index}></TrackerEquipment>
              </TabPanel>
              <TabPanel
                value="3"
                style={{
                  height: "345px",
                  overflow: "overlay",
                  padding: "10px",
                }}>
                <TrackerSetting index={props.index}></TrackerSetting>
              </TabPanel>
              <TabPanel value="4" style={{padding: "10px"}}>
                <TrackerActions index={props.index}></TrackerActions>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
        <Button variant="outlined" color="inherit" onClick={props.handleClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
